// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from 'msal';
import { setGlobal } from 'reactn';
import history from '../redux/history';

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: process.env.REACT_APP_OAUTH_AZURE_CLIENT_ID,
    redirectUri: `${process.env.REACT_APP_BASE_URL}/_oauth/azureAd`,
    postLogoutRedirectUri: `${process.env.REACT_APP_BASE_URL}/auth/signin`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loadFrameTimeout: 1,
    logger: new Logger(
      (logLevel, message, containsPii) => {
        // TODO still have issues when oauth token expires
        // console.log('[MSAL]', message);
        // if (message.includes('RenewIdToken expected')) {
        //   history.push('/auth/signout');
        // }
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    ),
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: process.env.REACT_APP_OAUTH_AZURE_SCOPES.split(','),
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html',
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
