import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, Flex } from 'rebass/styled-components';
import { withRouter, Redirect } from 'react-router-dom';
import { useGlobal } from 'reactn';
import Icon from '../../common/components/Icon';
import Divider from '../../common/components/Divider';
import LoginWithEmailPasswordForm from './LoginWithEmailPasswordForm';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from '../../../api/oauth/azure';

const AzureSvg = () => (
  <svg x="0px" y="0px" width="25px" height="25px" viewBox="0 0 439 439">
    <rect fill="#FFF" width="439" height="439" />
    <rect x="17" y="17" fill="#F35325" width="194" height="194" />
    <rect x="228" y="17" fill="#81BC06" width="194" height="194" />
    <rect x="17" y="228" fill="#05A6F0" width="194" height="194" />
    <rect x="228" y="228" fill="#FFBA08" width="194" height="194" />
  </svg>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MicrosoftIcon = (props) => <Icon component={AzureSvg} {...props} />;

const MiniCard = styled.div`
  width: 100%;
  min-height: 80px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  ${(props) =>
    props.onClick &&
    `  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }`}
  transition: box-shadow 200ms ease-in-out;
`;

const AzureCard = ({ message, onClick }) => (
  <MiniCard onClick={onClick}>
    <MicrosoftIcon />
    <Flex flexDirection="column">
      <Text ml=".5rem" fontSize={2}>
        Spruce Email
      </Text>
      <Text ml=".5rem" fontSize=".7rem">
        {message}
      </Text>
    </Flex>
  </MiniCard>
);

const SignIn = ({ location }) => {
  const [jwt, setJwt] = useGlobal('jwt');
  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);

  if (jwt) {
    return (
      <Redirect
        to={location.state?.referrerPathname + location.state?.referrerSearch || '/user/timesheets'}
      />
    );
  }

  return (
    <Wrapper>
      <Text ml="1rem" fontSize={3}>
        Log In
      </Text>
      <AzureAD provider={authProvider}>
        {({ login, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Unauthenticated:
              return <AzureCard message="Office 365" onClick={login} />;
            case AuthenticationState.InProgress:
              return <AzureCard message="Authenticating..." />;
            case AuthenticationState.Authenticated:
              setTimeout(() => {
                setJwt(accountInfo.jwtIdToken);
                localStorage.setItem('jwt', accountInfo.jwtIdToken)
              }, 1000);
              return <AzureCard message="Redirecting..." />;
          }
        }}
      </AzureAD>
      <Divider />
      {showEmailPasswordForm ? (
        <LoginWithEmailPasswordForm />
      ) : (
        <MiniCard onClick={() => setShowEmailPasswordForm(true)}>
          <Text fontSize={2}>Non-Spruce Email</Text>
        </MiniCard>
      )}
    </Wrapper>
  );
};

SignIn.defaultProps = {
  form: undefined,
};

export default withRouter(SignIn);
