import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import TimesheetReport from './TimesheetReport';
import { GET_TIMESHEET_BY_ID } from '../queries';

const TimesheetContainer = ({ match, location }) => {
  const { timesheetId } = match.params;
  const queryParams = queryString.parse(location.search);
  const { projectId = null } = queryParams;
  return (
    <Query query={GET_TIMESHEET_BY_ID} variables={{ timesheetId, projectId }}>
      {({ loading, error, data }) => {
        if (error || loading) return null;
        const { timesheet } = data.reports;
        return <TimesheetReport timesheet={timesheet} queryParams={queryParams} />;
      }}
    </Query>
  );
};

export default withRouter(TimesheetContainer);
