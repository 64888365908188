import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import moment from 'moment';
import Button from '../Button';
import DatePicker from '../DatePicker';
import Form from '../Form';
import Input from '../Input';
import { UPDATE_RESOURCE, ADD_RESOURCE } from './mutations';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const dateFormat = 'MM/DD/YYYY';

const UpdateResourceForm = ({
  onComplete,
  client,
  projectId,
  resource = {},
  refetchQueries = [],
}) => {
  const [hasError, setHasError] = useState(false);
  return (
    <Formik
      initialValues={{
        ...resource,
      }}
      validate={values => {
        const errors = {};
        // console.log({ values });
        // if (!values.password) {
        //   !setEmailCheck.isSpruceEmail
        //     ? (errors.password = 'Password is definitely required')
        //     : (errors.password = "I can't let you do this");
        // }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        // console.log({ values });
        if (resource._id) {
          return client
            .mutate({
              mutation: UPDATE_RESOURCE,
              variables: {
                id: resource._id,
                ...values,
              },
              awaitRefetchQueries: true,
              refetchQueries,
            })
            .then(() => {
              setSubmitting(false);
              onComplete();
            })
            .catch(err => {
              console.log('Error Message: ', err.message);
              setHasError(true);
            });
        } else {
          return client
            .mutate({
              mutation: ADD_RESOURCE,
              variables: {
                projectId,
                ...values,
              },
              awaitRefetchQueries: true,
              refetchQueries,
            })
            .then(() => {
              setSubmitting(false);
              onComplete();
            })
            .catch(err => {
              console.log('Error Message: ', err.message);
              setHasError(true);
            });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const { startDate, endDate, name, email, role } = values;
        return (
          <Form onSubmit={handleSubmit} {...formItemLayout}>
            <Form.Item label="Name" hasFeedback required>
              <Input name="name" onChange={handleChange} value={name} />
            </Form.Item>
            <Form.Item label="Email" hasFeedback required>
              <Input name="email" onChange={handleChange} value={email} />
            </Form.Item>
            <Form.Item label="Role" hasFeedback required>
              <Input name="role" onChange={handleChange} value={role} />
            </Form.Item>
            <Form.Item label="Start Date" hasFeedback required>
              <DatePicker
                onChange={momentDate => {
                  setFieldValue('startDate', momentDate.toDate());
                }}
                value={startDate ? moment(startDate) : null}
                format={dateFormat}
              />
            </Form.Item>
            <Form.Item label="End Date" hasFeedback>
              <DatePicker
                onChange={momentDate => {
                  setFieldValue('endDate', momentDate.toDate());
                }}
                value={endDate ? moment(endDate) : null}
                format={dateFormat}
              />
            </Form.Item>
            <div className="flex justify-end">
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                {resource._id ? 'Update Resource' : 'Add Resource'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withApollo(UpdateResourceForm);
