import React, { useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import queryString from 'query-string';
import gql from 'graphql-tag';

const CALL_OAUTH = gql`
  mutation Oauth($token: String!) {
    oauth(token: $token)
  }
`;

const Oauth = ({ location }) => {
  const parsed = queryString.parse(location.hash);
  const [loginWithOauth] = useMutation(CALL_OAUTH);

  useEffect(() => {
    if (parsed?.id_token) {
      loginWithOauth({
        variables: {
          token: parsed.id_token,
        },
      });
    }
  }, []);

  return <Redirect to="/user/timesheets" />;
};

export default withRouter(Oauth);
