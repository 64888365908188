/* eslint-disable import/no-unresolved */
import React from 'react';
import { setGlobal } from 'reactn';
import moment from 'moment';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ConfigProvider from 'antd/lib/config-provider';
import enUS from 'antd/lib/locale-provider/en_US';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import AuthLayout from './layouts/AuthLayout';
import MainLayout from './layouts/MainLayout';
import ReportsLayout from './layouts/ReportsLayout';
import client from './api/graphql/client';
import history from './api/redux/history';
import store from './api/redux/store';
import ErrorBoundary from './modules/error-handling/components/ErrorBoundary';
import NetworkIndicator from './modules/network-indicator/components/NetworkIndicator';
import { ApolloNetworkStatusProvider } from 'react-apollo-network-status';
import './api/sentry';
import '../node_modules/antd/dist/antd.css';
import './globalStyles';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

// setupLogRocketReact(LogRocket);
// LogRocket.init('rmseaw/spruce-app');

import { datadogRum } from '@datadog/browser-rum';

if (!window.location.host.includes('localhost')) {
  datadogRum.init({
    applicationId: 'fb71cfdb-dc28-4ff2-bb28-186447380560',
    clientToken: 'pubc597ee58e0a10d5398de3b327028a99b',
    site: 'us5.datadoghq.com',
    env: window.location.host.includes('.dev.sprucetech.com') ? 'staging' : 'production',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogRum.startSessionReplayRecording();
}

// default global state
setGlobal({
  networkStatus: {},
  user: null,
  jwt: localStorage.getItem('jwt'),
  loading: false,
  endDate: moment().endOf('isoWeek').format('YYYYMMDD'),
  range: {
    startDate: moment().subtract(2, 'month').startOf('month'),
    endDate:
      moment().endOf('month').diff(moment(), 'days') < 7
        ? moment().endOf('month').add(1, 'month')
        : moment().endOf('month'),
  },
});

const App = () => (
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ConfigProvider locale={enUS}>
            <ApolloNetworkStatusProvider>
              <NetworkIndicator />
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/user" />} />
                <Route path="/user" component={MainLayout} />
                <Route path="/project-management" component={MainLayout} />
                <Route path="/vaccine-tracker" component={MainLayout} />
                <Route path="/finance" component={MainLayout} />
                <Route path="/admin" component={MainLayout} />
                <Route path="/performance" component={MainLayout} />
                <Route path="/human-resources" component={MainLayout} />
                <Route path="/reports" component={ReportsLayout} />
                <Route path="/auth" component={AuthLayout} />
                <Route path="/_oauth" component={AuthLayout} />
                <Route render={() => <div>404</div>} />
              </Switch>
            </ApolloNetworkStatusProvider>
          </ConfigProvider>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  </ErrorBoundary>
);

export default App;
