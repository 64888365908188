import gql from 'graphql-tag';

export const ADMIN_GET_USER_BY_ID = gql`
  query getUserByIdAsAdmin($userId: String!) {
    admin {
      user(userId: $userId) {
        _id
        firstName
        lastName
        payrollId
        accountStatus
        emails {
          address
        }
        projects{
          _id
          name
          projectManagers{
            _id
            name
          }
        }
        approvers {
          type
          value
        }
        delegatedApprovers {
          type
          value
        }
        imageUrl
        title
        groups
      }
    }
  }
`;

export const ADMIN_GET_USERS = gql`
  query getUsersAsAdmin($manualUser: Boolean) {
    admin {
      users(manualUser: $manualUser) {
        _id
        firstName
        lastName
        lastActiveOn
        payrollId
        accountStatus
        emails {
          address
          verified
        }
        approvers {
          type
          value
        }
        delegatedApprovers {
          type
          value
        }
      }
    }
  }
`;

export const GET_TIMESHEET_THRESHOLD = gql`
  query getTimesheetThreshold {
    timesheetThreshold 
  }
`;
export const GET_WEEKLY_REMINDER_EMAIL = gql`
  query getTimesheetThreshold {
    weeklyReminderEmailJob 
  }
`;

export const EMAIL_STATS_RECEIVERS = gql`
  query emailStatsReceivers {
    admin  {
      emailStatsRecievers 
        users {
          _id
          firstName
          lastName
          lastActiveOn
          payrollId
          accountStatus
          emails {
            address
            verified
          }
          approvers {
            type
            value
          }
          delegatedApprovers {
            type
            value
          }
        }
        
    }
  }
`;
