import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
  > * {
    margin: 0 0.5em;
  }
`;

const TimesheetListActions = ({ timesheet, cancel, refetch }) => {
  const { status, endDate, _id: timesheetId, documentUrl } = timesheet;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ButtonsWrapper>
      <Tooltip title="Open Timesheet" trigger="hover">
        <Link to={`/user/timesheets/${endDate}`}>
          <Button>Open</Button>
        </Link>
      </Tooltip>
      {status === 'Approved' && (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="details">
                <a href={`/reports/timesheets/${timesheetId}?options=project-filter`} target="_blank">
                  Details
                </a>
              </Menu.Item>
              {documentUrl && (
                <Menu.Item key="download">
                  <a href={documentUrl} target="_blank">
                    Download
                  </a>
                </Menu.Item>
              )}
              <Menu.Item
                key="cancel"
                disabled={['Cancelled', 'Not Submitted'].includes(status)}
                onClick={async () => {
                  setIsLoading(true);
                  await cancel();
                  await refetch();
                  setIsLoading(false);
                }}
              >
                Cancel
              </Menu.Item>
            </Menu>
          }
        >
          <Button shape="circle" icon="ellipsis" loading={isLoading} />
        </Dropdown>
      )}
    </ButtonsWrapper>
  );
};

export default TimesheetListActions;
