import gql from 'graphql-tag';
import { Timesheet } from '../timesheets/queries';

export const GET_PROJECT_BY_ID = gql`
  query getProjectById($projectId: String) {
    finance {
      project(projectId: $projectId) {
        _id
        accountingCode
        projectCode
        name
        description
        client {
          shortName
        }
      }
    }
  }
`;

export const GET_TIMESHEETS_FOR_FINANCE = gql`
  query getTimesheetsForFinance($startDate: String, $endDate: String, $queryString: String) {
    finance {
      timesheetSnapshots(startDate: $startDate, endDate: $endDate, queryString: $queryString)
    }
    users {
      _id
      firstName
      lastName
      emails {
        address
      }
    }
  }
`;

export const GET_TIMESHEET_WEEKLY_SNAPSHOT = gql`
  query getTimesheetWeeklySnapshot($startDate: String, $endDate: String) {
    finance {
      timesheetSnapshots(startDate: $startDate, endDate: $endDate)
    }
  }
`;

export const GET_PROJECTS = gql`
  fragment project on Project {
    _id
    accountingCode
    projectCode
    name
    description
    client {
      shortName
    }
  }
  query getProjects {
    finance {
      allProjects {
        ...project
      }
    }
  }
`;
