import React, { setGlobal } from 'reactn';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import Button from '../../common/components/Button';
import TimesheetGridRowComment from './TimesheetGridRowComment';
import TimesheetGridRowHourContainer from './TimesheetGridRowHourContainer';
import { GET_USER_TIMESHEET } from '../queries';
import { REMOVE_TIMESHEET_LINEITEM } from '../mutations';

const Col = styled.td`
  padding: 1em;
  width: ${props => (props.width ? props.width : 'initial')};
  text-align: ${props => (props.align ? props.align : 'initial')};
  &:first-child {
    padding-left: 0;
  }
`;

const TaskTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const setLoading = (loading, delay) => {
  if (delay) {
    setTimeout(() => {
      setGlobal({ loading });
    }, delay);
  } else {
    setGlobal({ loading });
  }
};

class TimesheetGridRow extends React.Component {
  state = {
    removeLoading: false,
  };

  handleRemoveRow = () => {
    setLoading(true);
    this.setState({ removeLoading: true });
    setTimeout(() => {
      this.props.client
        .mutate({
          mutation: REMOVE_TIMESHEET_LINEITEM,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_USER_TIMESHEET,
              variables: {
                endDate: this.props.endDate,
                user: this.props.user,
              },
            },
          ],
          variables: {
            timesheetId: this.props.timesheetId,
            lineItemIndex: this.props.lineItemIndex,
            user: this.props.user,
          },
        })
        // componented will be unmounted so no need to call setState
        .then(() => setLoading(false));
    }, 500);
  };

  render() {
    const { timesheetId, lineItem, isLocked, endDate } = this.props;
    const { project, deliverable } = lineItem;
    const newTaskRequiredFields = {
      timesheetId,
      projectId: project._id,
      deliverableId: deliverable._id,
    };
    return (
      <tr>
        <Col>
          <TaskTitleContainer>
            {`${deliverable.milestoneName} / ${deliverable.deliverableName}`}
          </TaskTitleContainer>
        </Col>
        <Col>
          <TimesheetGridRowComment isLocked={isLocked} lineItem={lineItem} user={this.props.user} />
        </Col>
        {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, index) => {
          const date = moment(endDate)
            .subtract(6 - index, 'days')
            .format('YYYYMMDD');
                      // disabled hours for dates after 2023-05-26
          const disabled = moment(date).isAfter('2023-09-17', 'day');
          return (
            <Col key={day} align="center">
              <TimesheetGridRowHourContainer
                isLocked={isLocked}
                disabled={disabled}
                user={this.props.user}
                task={lineItem[`${day}Task`]}
                newTaskRequiredFields={{
                  ...newTaskRequiredFields,
                  date,
                }}
              />
            </Col>
          );
        })}
        {!this.props.isLocked && (
          <Col align="center">
            <Button
              onClick={this.handleRemoveRow}
              loading={this.state.removeLoading}
              disabled={this.global.loading}
              shape="circle"
              icon="close"
              type="danger"
              ghost
            />
          </Col>
        )}
      </tr>
    );
  }
}

export default withApollo(TimesheetGridRow);
