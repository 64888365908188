import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Tabs, { TabPane } from '../../common/components/Tabs';
import Card from '../../common/components/Card';
import ProjectListContainer from './ProjectListContainer';
import ProjectDetailsContainer from './ProjectDetailsContainer';

const ProjectManagement = () => (
  <div>
    <Switch>
      <Route path="/project-management/projects" render={() => <ProjectListContainer />} />
    </Switch>
  </div>
);

export default ProjectManagement;
