import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactResizeDetector from 'react-resize-detector';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { datadogRum } from '@datadog/browser-rum';
import TopBar from '../modules/topbar/components/TopBar';
import SideBar from '../modules/sidebar/components/SideBar';
import TimesheetsPage from '../modules/timesheets/components/TimesheetsPage';
import ProjectManagerPage from '../modules/project-manager/components/ProjectManagerPage';
import FinancePage from '../modules/finance/components/FinancePage';
import AdminPage from '../modules/admin/components/AdminPage';
import ProfilePage from '../modules/profile/components/ProfilePage';
import PerformancePage from '../modules/performance/components/PerformancePage';
import HumanResourcesPage from '../modules/human-resources/components/HumanResourcesPage';
import TextLoader from '../modules/common/components/TextLoader';
// import FeedbackButton from '../modules/feedback/components/FeedbackButton';
import { fadeIn, GlobalStyle } from '../globalStyles';
import VaccineTrackerPage from '../modules/vaccine-tracker/components/VaccineTrackerPage';

export const ME = gql`
  fragment MenuItem on MenuItem {
    label
    iconName
    linkUrl
    overrideMatchUrl
  }
  {
    me {
      _id
      firstName
      lastName
      title
      imageUrl
      emails {
        address
      }
      groups
      employeeType
      menu {
        ...MenuItem
        children {
          ...MenuItem
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  background-color: #e5e8ee;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-x: auto;
`;

const Layout = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
  ${props =>
    props.isOffline &&
    `
  opacity: .5;
  pointer-events: none;
  user-select: none;`}
`;

const MainPane = styled.div`
  display: flex;
  transition: opacity 200ms;
`;

const Top = styled.div`
  min-width: ${props => (props.sidebarCollapsed ? 1224 - props.sidebarWidth : 1224)}px;
  transition: min-width 200ms 300ms;
`;

const Side = styled.div`
  animation: ${fadeIn} 0.2s linear;
  ${props => (props.collapsed ? 'min-width: 0px;' : `min-width: ${props.width}px;`)}
  ${props => (props.collapsed ? 'width: 0px;' : `width: ${props.width}px;`)}
  ${props => props.collapsed && 'opacity: 0;'}
  max-width: ${props => props.width}px;
  transition: min-width 200ms 300ms, opacity 200ms;
  ${props => !props.collapsed && 'transition: opacity 200ms 300ms, min-width 200ms;'}
`;

const Content = styled.div`
  max-width: ${props =>
    props.sidebarCollapsed ? props.maxWidth + props.sidebarWidth : props.maxWidth}px;
  min-width: ${props => props.minWidth}px;
  width: calc(100vw - ${props => (props.sidebarCollapsed ? 0 : props.sidebarWidth)}px);
  transition: all 200ms;
  min-height: calc(100vh - 75px);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0 0.5rem;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
`;

const sidebarWidth = 230;
const contentMaxWidth = 1115;
const contentMinWidth = 970;

const MainLayout = ({ userTempPasswordCheck, pathname, search }) => {
  const [jwt] = useGlobal('jwt');
  const [user, setUser] = useGlobal('user');
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { loading, data } = useQuery(ME);
  const [networkStatus] = useGlobal('networkStatus');
  const { isOffline } = networkStatus;

  // useEffect(() => {
  //   setTimeout(() => setSidebarCollapsed(!sidebarCollapsed), 3000);
  // }, []);

  let hasTempPassword;
  if (userTempPasswordCheck) {
    hasTempPassword = userTempPasswordCheck.profile.tempPassword;
  }

  if (!jwt) {
    return (
      <Redirect
        to={{
          pathname: '/auth/signin',
          state: { referrerPathname: pathname, referrerSearch: search },
        }}
      />
    );
  }

  if (data?.me && !user) {
    setUser(data.me);
    datadogRum.setUser({
      id: data?.me._id,
      name: `${data?.me.firstName} ${data?.me.lastName}`,
      email: data?.me.emails[0].address,
    });
  }

  if (loading || !user) {
    return <TextLoader text="Loading user data..." />;
  }

  return (
    <Wrapper>
      <GlobalStyle />
      <ReactResizeDetector
        handleWidth
        onResize={width => {
          if (width < 800 && !sidebarCollapsed) {
            setSidebarCollapsed(true);
          }

          if (width > 800 && sidebarCollapsed) {
            setSidebarCollapsed(false);
          }
        }}
      />
      <Layout isOffline={isOffline}>
        <Top sidebarCollapsed={sidebarCollapsed || hasTempPassword} sidebarWidth={sidebarWidth}>
          <TopBar
            toggleSidebar={() => setSidebarCollapsed(!sidebarCollapsed)}
            sidebarCollapsed={sidebarCollapsed || hasTempPassword}
          />
        </Top>
        <MainPane>
          <Side collapsed={sidebarCollapsed || hasTempPassword} width={sidebarWidth}>
            <SideBar />
          </Side>
          <Content
            sidebarCollapsed={sidebarCollapsed || hasTempPassword}
            sidebarWidth={sidebarWidth}
            maxWidth={contentMaxWidth}
            minWidth={contentMinWidth}
          >
            <Switch>
              <Route exact path="/user" render={() => <Redirect to="/user/timesheets" />} />
              <Route exact path="/user/timesheets" component={TimesheetsPage} />
              <Route exact path="/user/timesheets/:endDate" component={TimesheetsPage} />
              <Route exact path="/user/profile" component={ProfilePage} />
              <Route path="/project-management" component={ProjectManagerPage} />
              <Route
                exact
                path="/vaccine-tracker"
                render={() => <Redirect to="/vaccine-tracker/proofs" />}
              />
              <Route exact path="/vaccine-tracker/proofs" component={VaccineTrackerPage} />
              <Route exact path="/vaccine-tracker/report" component={VaccineTrackerPage} />
              <Route path="/finance" component={FinancePage} />
              <Route path="/admin" component={AdminPage} />
              <Route path="/performance" component={PerformancePage} />
              <Route path="/human-resources" component={HumanResourcesPage} />
            </Switch>
          </Content>
        </MainPane>
      </Layout>
      {/* <FeedbackButton /> */}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  userTempPasswordCheck: state.meteor.user,
  pathname: get(state, 'router.location.pathname'),
  search: get(state, 'router.location.search'),
});

export default connect(mapStateToProps)(MainLayout);
