/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String
    $firstName: String
    $lastName: String
    $notify: Boolean
    $payrollId: String
  ) {
    createUser(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        notify: $notify
        payrollId: $payrollId
      }
    )
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($id: String, $email: String, $password: String!) {
    resetUserPassword(input: { id: $id, email: $email, password: $password })
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $title: String
    $firstName: String
    $lastName: String
    $payrollId: String
    $userId: String!
  ) {
    updateUser(
      input: {
        title: $title
        firstName: $firstName
        lastName: $lastName
        payrollId: $payrollId
        userId: $userId
      }
    )
  }
`;

export const ADD_APPROVER = gql`
  mutation addApprover($userEmail: String!, $type: String!, $value: String, $delegated: Boolean) {
    addApprover(userEmail: $userEmail, type: $type, value: $value, delegated: $delegated)
  }
`;

export const REMOVE_APPROVER = gql`
  mutation removeApprover(
    $userEmail: String!
    $type: String!
    $value: String
    $delegated: Boolean
  ) {
    removeApprover(userEmail: $userEmail, type: $type, value: $value, delegated: $delegated)
  }
`;

export const ACTIVE_INACTIVE_ACCOUNT = gql`
  mutation ActiveInactiveAccount($id: String, $status: Boolean ) {
    activeInactiveAccount( id: $id, status: $status )
  }
  `;
export const ADD_TO_GROUP = gql`
  mutation addToGroup($userEmail: String!, $name: String!) {
    addToGroup(userEmail: $userEmail, name: $name)
  }
`;
export const REMOVE_FROM_GROUP = gql`
  mutation removeFromGroup($userEmail: String!, $name: String!) {
    removeFromGroup(userEmail: $userEmail, name: $name)
  }
`;
export const TIMESHEET_THRESHOLD = gql`
  mutation timesheetThreshold($min: Int!,$max:Int!) {
    timesheetThreshold(min: $min,max:$max)
  }
`;
export const EMAIL_STATS_RECIEVER = gql`
mutation emailStatsReciever($emails:String) {
  emailStatsReciever(emails: $emails)
}
`;

export const EMAIL_REMINDER_JOB = gql`
mutation reminderEmailJob($cronExpression:String, $cronDescription:String,$currentScheduler:String) {
  reminderEmailJob(cronExpression: $cronExpression,cronDescription:$cronDescription,currentScheduler:$currentScheduler)
}
`;
