import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import VaccineProofContainer from './VaccineProofContainer';
import VaccineReportContainer from './VaccineReportContainer';

const VaccineTrackerPage = () => {
  return (
    <Switch>
      <Route
        exact
        path="/vaccine-tracker/proofs"
        render={() => <VaccineProofContainer />}
      />
      <Route
        exact
        path="/vaccine-tracker/report"
        render={() => <VaccineReportContainer />}
      />
    </Switch>
  );
};

export default withRouter(VaccineTrackerPage);
