import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from '../../common/components/Card';

const Wrapper = styled.div`
  color: initial;
`;

const HrReports = () => {
  return (
    <Card floating padded={false} bodyBackground="none" title="Reports">
      <div className="flex">
        <Link className="w-1/3 p-2" to="/human-resources/reports/all-users">
          <Card padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">All Active Users </h2>
              <p className="text-gray-600">Show list of all users, their projects and approvers.</p>
            </Wrapper>
          </Card>
        </Link>
        <Link className="w-1/3 p-2" to="/human-resources/reports/delinquency">
          <Card padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Delinquency Report </h2>
              <p className="text-gray-600">Show list of users, their latency in terms of submitting the timesheet</p>
            </Wrapper>
          </Card>
        </Link>
      </div>
    </Card>
  );
};

export default HrReports;
