import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import gql from 'graphql-tag';
import TextLoader from '../../common/components/TextLoader';
import ResourcePerformanceDetailByProject from './ResourcePerformanceDetailByProject';

export const PERFORMANCE_DETAIL = gql`
  query performance($startDate: String, $endDate: String, $userId: String!) {
    admin {
      user(userId: $userId) {
        tasks(startDate: $startDate, endDate: $endDate) {
          hours
          date
          project {
            clientName
          }
        }
        timesheets(startDate: $startDate, endDate: $endDate) {
          _id
          endDate
          status
          totalHours
        }
      }
    }
  }
`;

const ResourcePerformanceDetail = ({ userId, dateRange }) => {
  const { loading, data = {} } = useQuery(PERFORMANCE_DETAIL, {
    variables: {
      userId,
      startDate: dateRange[0],
      endDate: dateRange[1],
    },
  });
  const [selectedTimesheetId, setSelectedTimesheetId] = useState(null);

  useEffect(() => {
    if (!loading) {
      setSelectedTimesheetId(data?.admin?.user?.timesheets[0]?._id);
    }
  }, [loading]);

  if (loading) {
    return <TextLoader />;
  }

  const timesheets = data?.admin?.user?.timesheets;

  return (
    <div className="flex">
      <div className="w-1/3 flex flex-wrap">
        {timesheets.map(t => {
          return (
            <Tooltip key={t._id} title={moment(t.endDate).format('LL')}>
              <div
                onClick={() => setSelectedTimesheetId(t._id)}
                className={classNames(
                  'h-12 w-12 m-1 flex justify-center items-center cursor-pointer',
                  {
                    'bg-green-100': t.status === 'Approved',
                    'bg-orange': t.status === 'Submitted-Pending',
                    'bg-gray-200': t.status === 'Not Submitted' || t.status === 'Cancelled',
                    'bg-red-100': t.status === 'Declined',
                    'border-2 border-solid': selectedTimesheetId === t._id,
                  },
                )}
              >
                {t.totalHours}
              </div>
            </Tooltip>
          );
        })}
      </div>
      <div className="w-3/4">
        <ResourcePerformanceDetailByProject timesheetId={selectedTimesheetId}/>
      </div>
    </div>
  );
};

export default ResourcePerformanceDetail;
