import React from 'react';
import { useGlobal } from 'reactn';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Icon from '../../common/components/Icon';

const Menu = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  font-size: 1em;
  ${(props) => props.active && 'border-left: 3px solid #8192ff;'}
  transition: border-left 300ms;
  a {
    color: #2b2d50;
    text-decoration: none;
  }
`;

const ItemIcon = styled(Icon)`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
`;

const SubMenu = styled(Menu)`
  padding-left: 40px;
  padding-bottom: 10px;
`;

const SubItem = styled(Item)`
  ${(props) => !props.active && 'a {color: #4e566d;}'};
`;

const ItemWrapper = styled.div`
  display: flex;
`;

const determineIfShouldBeActive = (currentUrl, matchUrl, useRegex = false) => {
  if (useRegex) {
    const regex = new RegExp(matchUrl);
    return regex.test(currentUrl);
  }
  return currentUrl === matchUrl;
};

// handleClick = i => {
//   this.props.history.push(`/${i.key}`);
// };
const SideMenu = (props) => {
  const [user] = useGlobal('user');
  const { urlPath } = props;
  const menu = get(user, 'menu')

  if (!menu) {
    return null;
  }

  return (
    <Menu>
      {menu.map((item, i) => {
        const { children } = item;
        return (
          <Item key={i + item.label}>
            <ItemWrapper>
              <ItemIcon type={item.iconName} />
              <ItemLabel>{item.label}</ItemLabel>
            </ItemWrapper>
            {children && children.length > 0 && (
              <SubMenu>
                {children.map((subItem, y) => {
                  const { linkUrl, overrideMatchUrl } = subItem;
                  const match = determineIfShouldBeActive(
                    urlPath,
                    overrideMatchUrl || linkUrl,
                    !!overrideMatchUrl,
                  );
                  return (
                    <SubItem key={i + y + subItem.label} active={match}>
                      <Link to={subItem.linkUrl}>{subItem.label}</Link>
                    </SubItem>
                  );
                })}
              </SubMenu>
            )}
          </Item>
        );
      })}
    </Menu>
  );
};

export default SideMenu;
