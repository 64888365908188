import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { Route, Switch, Redirect } from 'react-router-dom';
import Tabs, { TabPane } from '../../common/components/Tabs';
import Card from '../../common/components/Card';
import ProjectCard from '../../common/components/ProjectCard';
import ProjectDetailsTimesheetList from './ProjectDetailsTimesheetList';
import ProjectDetailsResourceList from './ProjectDetailsResourceList';
import ProjectDetailsDeliverableList from './ProjectDetailsDeliverableList';
import TimesheetSnapshotCard from './TimesheetSnapshotCard';
import ProjectRoles from './ProjectRoles';
import ProjectFinancials from './ProjectFinancials';

const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    margin-bottom: 0;
  }
`;

const FlexWithGap = styled(Flex)`
  > :first-child {
    padding-left: 0;
  }
  > :last-child {
    padding-right: 0;
  }
`;

const Heading = styled.h1`
  margin-bottom: 0;
`;

const ProjectDetails = ({ user, goToUrl, project, activeTab, history,refetchQueries }) => {
  if (!project) return null;
  // const [isTimesheetListPaginated, setIsTimesheetListPaginated] = useState(true);
  // const [isResourceListPaginated, setIsResourceListPaginated] = useState(true);
  // const [isDeliverableListPaginated, setIsDeliverableListPaginated] = useState(true);
  const { location } = history;
  const { pathname } = location;
  let pathPrefix = '/project-management/projects';
  if (pathname.includes('finance')) {
    pathPrefix = pathPrefix.replace('project-management', 'finance');
  }
  if (!activeTab) {
    return <Redirect to={`${pathname}/timesheets`} />;
  }
  return (
    <Flex flexDirection="column" pb="6rem">
      <ProjectCard project={project} refetchQueries={refetchQueries} />
      <Box width={1} mt=".5rem">
        <Card fadeIn withShadow padded={false} floating>
          <StyledTabs
            defaultActiveKey={activeTab}
            onChange={activeTab => goToUrl(`${pathPrefix}/${project._id}/${activeTab}`)}
          >
            <TabPane tab="Timesheets" key="timesheets">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/timesheets`}
                  render={() => <ProjectDetailsTimesheetList projectId={project._id} />}
                />
              </Switch>
            </TabPane>
            <TabPane tab="Resources" key="resources">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/resources`}
                  render={() => <ProjectDetailsResourceList projectId={project._id} />}
                />
              </Switch>
            </TabPane>
            <TabPane tab="Deliverables" key="deliverables">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/deliverables`}
                  render={() => <ProjectDetailsDeliverableList projectId={project._id} />}
                />
              </Switch>
            </TabPane>
            <TabPane tab="Roles" key="roles">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/roles`}
                  render={() => <ProjectRoles projectId={project._id} />}
                />
              </Switch>
            </TabPane>
            {/* <TabPane tab="Financials" key="financials">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/financials`}
                  render={() => <ProjectFinancials projectId={project._id} />}
                />
              </Switch>
            </TabPane> */}
            <TabPane tab="Export Hours" key="export">
              <Switch>
                <Route
                  path={`${pathPrefix}/:projectId/export`}
                  render={() => <TimesheetSnapshotCard projectId={project._id} />}
                />
              </Switch>
            </TabPane>
          </StyledTabs>
        </Card>
      </Box>
    </Flex>
  );
};

export default ProjectDetails;
