import React, { useState } from 'react';
import styled from 'styled-components';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import Card from '../../common/components/Card';

const Wrapper = styled.div`
  height: 700px;
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

const ProjectFinancials = () => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);

  const onGridReady = params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setRowData([
      {
        athlete: 'bob',
        age: 3,
      },
      {
        athlete: 'bob 2',
        age: 55,
      },
    ]);
  };

  return (
    <Card>
      <div className="flex justify-between flex-col">
        <div className="p-2">
          <div>dasdsad</div>
          <div>dasdsad</div>
        </div>
        <Wrapper className="ag-theme-balham">
          <AgGridReact
            enableCellTextSelection
            modules={[AllCommunityModules]}
            defaultColDef={{
              // width: 200,
              sortable: true,
              filter: true,
              resizable: true,
            }}
            rowData={rowData}
            // pinnedBottomRowData={createData(1, 'Bottom')}
            onGridReady={onGridReady}
          >
            <AgGridColumn field="athlete" />
            <AgGridColumn field="age" />
          </AgGridReact>
        </Wrapper>
      </div>
    </Card>
  );
};

export default ProjectFinancials;
