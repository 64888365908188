import gql from 'graphql-tag';

export const GET_END_CLIENTS = gql`
  query getClients {
    allEndClients {
      _id
      shortName
      name
    }
  }
`;
