import gql from 'graphql-tag';

export const GET_USER_APPROVERS = gql`
  query getUserApprovers {
    user {
      approvers {
        type
        value
      }
    }
  }
`;

export const GET_USER_DELEGATED_APPROVERS = gql`
  query getUserDelegatedApprovers {
    user {
      delegatedApprovers {
        type
        value
      }
    }
  }
`;
