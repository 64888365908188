import React,{useState}  from 'react';
import Card from '../../common/components/Card';
import Table from '../../common/components/Table';
import { CSVLink } from 'react-csv';
import { searchTable } from './SearchTable';

const NotRespondedReport = ({ data, title }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const columns = [
    {
      title: 'Employee',
      dataIndex: 'employee',
      key: 'employee',
      ...searchTable('employee',searchText, setSearchText,searchedColumn, setSearchedColumn),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ...searchTable('type',searchText, setSearchText,searchedColumn, setSearchedColumn)
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
    },
  ];
  return (
    <Card
      title={title + ' : ' + data.length}
      actionComponent={
        <div style={{ padding: '10px' }}>
          <CSVLink
            filename={'VaccineTracker_NotResponded_' + Date.now() + '.csv'}
            data={data}
            className="btn"
          >
            Export CSV
          </CSVLink>{' '}
        </div>
      }
    >
      <div>
        <Table
          highlightOnHover
          columns={columns}
          pagination={false}
          dataSource={data && data.map((r, i) => ({ key: i, ...r }))}
          rowKey={r => r.lastLogin + r.employee + Math.random()}
          style={{ height: '400px' }}
        />
      </div>
    </Card>
  );
};

export default NotRespondedReport;
