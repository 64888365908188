import React from 'react';
import { Query } from 'react-apollo';
import Timesheet from '../../timesheets/components/Timesheet';
import TimesheetLoading from '../../timesheets/components/TimesheetLoading';
import { GET_TIMESHEET_BY_ID } from '../queries';

export default ({ timesheetId }) => (
  <Query query={GET_TIMESHEET_BY_ID} variables={{ timesheetId }}>
    {({ loading, error, data }) => {
      if (error) return <div>error</div>;
      if (loading || !data) return <TimesheetLoading />;
      const { timesheet } = data.projectManagement;
      return <Timesheet timesheet={timesheet} endDate={timesheet.endDate} readOnly />;
    }}
  </Query>
);
