import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UserListContainer from './UserListContainer';
import UserDetailsContainer from './UserDetailsContainer';
import SettingsContainer from './SettingsContainer';

const AdminPage = () => (
  <Switch>
    <Route exact path="/admin" render={() => <Redirect to="/admin/users" />} />
    <Route exact path="/admin/users" render={() => <UserListContainer />} />
    <Route exact path="/admin/users/:userId" render={() => <UserDetailsContainer />} />
    <Route exact path="/admin/settings" render={() => <SettingsContainer />} />
  </Switch>
);

export default AdminPage;
