import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllUsersCard from './AllUsersCard';
import DelinquencyReportCard from './DelinquencyReportCard';
import HrReports from './HrReports';
import UserHourlyCostList from './UserHourlyCostList';

const HumanResourcesPage = () => {
  return (
    <Switch>
      <Route exact path="/human-resources/employee-cost" render={() => <UserHourlyCostList />} />
      <Route exact path="/human-resources/reports" render={() => <HrReports />} />
      <Route
        exact
        path="/human-resources/reports/all-users"
        render={() => <AllUsersCard />}
      />
      <Route
        exact
        path="/human-resources/reports/delinquency"
        render={() => <DelinquencyReportCard />}
      />
    </Switch>
  );
};

export default HumanResourcesPage;
