import React, { useState } from 'react';
import { Upload, message, Icon, DatePicker, Button, Spin, Input } from 'antd';
import Form from '../../common/components/Form';
import client from '../../../api/graphql/client';
import { UPLOAD_TIMESHEET_ATTACHMENT, DELETE_TIMESHEET_ATTACHMENT } from '../mutations';
import { showSuccess, showError } from '../../common/notifications';
import { GET_USER_TIMESHEET } from '../queries';
import { uploadFileToAzureBlob } from '../../common';
import moment from 'moment';

const TimesheetAttachments = ({ timesheet, endDate, readonly }) => {
  const { timesheetAttachments,startDate } = timesheet;
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState(`${moment(startDate).format('MMMM Do')} client timesheet`);

  const beforeUpload = file => {
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error('Image must smaller than 20MB!');
    }
    return isLt20M;
  };
  const uploadButton = uploading ? (
    <Button>
      <Spin style={{ padding: '5px' }} />
      <span style={{ padding: '5px' }}>Uploading..</span>
    </Button>
  ) : (
    <Button>
      <Icon type="upload" /> Upload Attachments
    </Button>
  );
  const uploadHandler = async ({ file }) => {
    setUploading(true);
    const { type,name } = file;
    const fileName = `${Date.now()}_${name}`;
    const url = await uploadFileToAzureBlob({
      fileName,
      file,
      blobContainerName: 'uploads',
    });
    client
      .mutate({
        mutation: UPLOAD_TIMESHEET_ATTACHMENT,
        variables: {
          timeSheetId: timesheet._id,
          url: url,
          description: description,
        },
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: { endDate },
          },
        ],
      })
      .then(() => {
        showSuccess({ message: 'Updated Successfully' });
        setUploading(false);
      })
      .catch(err => showError({ message: err.message }));
  };

  const deleteHandler = id => {
    client
      .mutate({
        mutation: DELETE_TIMESHEET_ATTACHMENT,
        variables: {
          timeSheetId: timesheet._id,
          id: id,
        },
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: { endDate },
          },
        ],
      })
      .then(() => {
        showSuccess({ message: 'Deleted Successfully' });
        setUploading(false);
      })
      .catch(err => showError({ message: err.message }));
  };

  return (
    <div>
      {!readonly && (
        <div style={{ margin: '10px', fontSize: '15px' }}>
          <div
            className="flex"
            style={{
              margin: '10px',
              fontSize: '15px',
              borderRadius: '15px',
            }}
          >
            <Form layout="inline" className="flex">
              <Form.Item label="Brief Description" className="">
                <Input
                  name="description"
                  value = {description}
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item label="" className="">
                <Upload
                  name="attachment"
                  customRequest={value => uploadHandler(value)}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  showUploadList={false}
                  disabled={uploading ? true : false}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}

      <div>
        {timesheetAttachments?.map(item => {
          return (
            <div style={{ margin: '10px', fontSize: '15px' }} key={item._id}>
              <div
                className="flex"
                style={{
                  margin: '10px',
                  border: '1px solid #d7d5d5',
                  fontSize: '15px',
                  borderRadius: '15px',
                }}
              >
                <div style={{ width: '100%', margin: '5px' }}>
                  <p style={{ float: 'left', paddingLeft: '10px' }}>
                    {item.description ? item.description : 'No Description'}
                  </p>
                  <div style={{ float: 'right' }}>
                    <Icon
                      type="paper-clip"
                      style={{ marginTop: '3px', padding: '0px 5px 0px 5px' }}
                    />
                    <a href={item.url} target="_blank" style={{ paddingRight: '10px' }}>
                      {item.url.replace(
                        'https://spruceportaldev.blob.core.windows.net/uploads/',
                        '',
                      )}
                    </a>
                    {timesheet.status == 'Not Submitted' && !readonly && (
                      <Button
                        type="link"
                        style={{ color: 'red' }}
                        onClick={() => {
                          deleteHandler(item._id);
                        }}
                      >
                        <Icon type="delete" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {(!timesheetAttachments || timesheetAttachments.length === 0) && readonly && (
        <div style={{ textAlign: 'center', margin: '10px', padding: '10px' }}>No Attachments</div>
      )}
    </div>
  );
};

export default TimesheetAttachments;
