import React from 'react';
import { Avatar, Tag } from 'antd';

const Profile = ({
  imageUrl,
  firstName = '',
  lastName = '',
  title,
  email,
  noWrap = false,
  employeeType,
  ...rest
}) => {
  let name = `${firstName} ${lastName}`.trim();
  return (
    <div className="flex" {...rest}>
      {imageUrl ? (
        <Avatar className="shadow-md" size={64} src={imageUrl} />
      ) : (
        <Avatar className="shadow-md" size={64}>{`${
          firstName?.charAt(0) + lastName?.charAt(0)
        }`}</Avatar>
      )}
      <div className="ml-2">
        <div className="font-medium text-lg whitespace-no-wrap">
          {<span>{name}</span>}
          {employeeType && (
            <Tag color={employeeType === 'W-2' ? 'blue' : 'orange'} className="ml-2">
              {employeeType}
            </Tag>
          )}
        </div>
        {name.trim() === '' && (
          <div className="whitespace-no-wrap">User has not logged in yet</div>
        )}
        {title && <div className="font-light whitespace-no-wrap truncate" style={{ maxWidth: '190px'}}>{title}</div>}
        {email && <div className="font-light whitespace-no-wrap">{email}</div>}
      </div>
    </div>
  );
};

export default Profile;
