import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { Input, Icon } from 'antd';
import { get, truncate } from 'lodash';
import Tag from '../../common/components/Tag';
import Table from '../../common/components/Table';
import Tooltip from '../../common/components/Tooltip';
import TextLoader from '../../common/components/TextLoader';
import Button from '../../common/components/Button';
import { getColorByStatus } from '../../common';
import TimesheetUploadsContainer from '../../timesheets/components/TimesheetUploadsContainer';
import Modal from '../../common/components/Modal';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
  > * {
    margin: 0 0.5em;
  }
`;
class TimesheetList extends React.Component {
  state = {
    searchInput: null,
    currentTimesheet: {},
    visible: false,
  };

  setSearchInput = searchInput => this.setState({ searchInput });
  showModal = timesheet => {
    this.setState({
      visible: true,
      currentTimesheet: timesheet,
    });
  };
  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const { timesheets, loading, users } = this.props;

    if (loading) {
      return <TextLoader text="Loading timesheets" />;
    }

    let { searchInput } = this.state;

    const onSearch = (selectedKeys, confirm) => {
      confirm();
      this.setSearchInput(selectedKeys[0]);
    };

    const resetSearch = clearFilters => {
      clearFilters();
      this.setSearchInput(null);
    };
    const columns = [
      {
        title: 'Resource Name',
        dataIndex: 'user',
        key: 'resourceName',
        width: 200,
        onFilter: (value, timesheet) => timesheet.user._id === value,
        render: user => (
          <div>
            <div className="font-bold">{`${user.firstName} ${user.lastName}`}</div>
            <div className="text-xs">{get(user, 'emails[0].address')}</div>
          </div>
        ),

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search Name`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => onSearch(selectedKeys, confirm, 'firstName')}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => onSearch(selectedKeys, confirm, 'firstName')}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => resetSearch(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, { user, resource }) => {
          const { firstName, lastName } = user;
          const searchString = firstName + lastName;
          return searchString.toString().toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select());
          }
        },
      },
      {
        title: 'Week',
        dataIndex: 'endDate',
        key: 'weekOf',
        render: endDate => moment(endDate).format('L'),
      },
      {
        title: 'Approvers',
        dataIndex: 'approvalsRequired',
        key: 'approvalsRequired',
        width: 100,
        render: data => {
          const approvers = [];
          const findApprovers = data?.map(item => {
            if (item.status === 'Approved') {
              const approvedBy = users.find(user => user.emails[0].address === item.approvedBy);
              approvers.push(`${approvedBy.firstName || ''} ${approvedBy.lastName || ''}`);
            }
          });
          return (
            <Tooltip title={approvers.toString()} trigger="hover">
              {approvers.toString()}
            </Tooltip>
          );
        },
      },
      {
        title: 'Projects',
        dataIndex: 'lineItems',
        key: 'Projects',
        render: lineItems => {
          const projects = lineItems.reduce((acc, cur) => {
            const project = `${cur.project.client.shortName} ${cur.project.name}`;
            if (acc.includes(project)) {
              return acc;
            }
            return [project, ...acc];
          }, []);
          return (
            <div>
              {projects.map(project => (
                <div className="whitespace-no-wrap">
                  {truncate(project, {
                    length: 24,
                    separator: '...',
                  })}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Hours',
        dataIndex: 'totalHours',
        key: 'totalHours',
        width: 50,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 200,
        filters:
          !loading &&
          timesheets.reduce((acc, cur) => {
            const { status } = cur;
            if (acc.find(i => i.value === status)) {
              return acc;
            }
            return [...acc, { text: status, value: status }];
          }, []),
        onFilter: (value, timesheet) => timesheet.status === value,
        render: (status,ts) => (
          <div>
            <Tooltip title="Overall timesheet status" trigger="hover">
              <Tag color={getColorByStatus(status)}>{status}</Tag>
            </Tooltip>
            {
            (ts.timesheetAttachments?.length >0 || ts.timesheetExpense?.attachments.length > 0) && (
                <Tooltip title={'View timesheet attachments'} trigger="hover">
                  <Button
                    onClick={() => {
                      this.showModal(ts);
                    }}
                    shape="circle"
                    icon="paper-clip"
                  />
                </Tooltip>
              )
            }
          </div>
        ),
      },
      {
        key: 'actions',
        width: 100,
        render: timesheet => {
          const { documentUrl, _id } = timesheet;
          return (
            <ButtonsWrapper>
              <a target="_blank" href={`/reports/timesheets/${_id}`}>
                View Timesheet
              </a>
            </ButtonsWrapper>
          );
        },
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          locale={{
            emptyText: 'No timesheets for that range',
          }}
          dataSource={
            // make sure timesheets exist and arrived to browser
            timesheets &&
            timesheets
              // add key property to timesheet objects. Its used by <Table />
              .map(t => ({ ...t, key: t._id }))
              // sort by endDate
              .sort((a, b) => {
                const diff = moment(b.endDate).diff(moment(a.endDate), 'days');
                return diff;
              })
          }
          rowKey={r => Math.random()}
        />
        {Object.keys(this.state.currentTimesheet).length>0 && <Modal
          title={`${this.state.currentTimesheet.user.firstName} ${this.state.currentTimesheet.user.lastName}`}
          width={1200}
          visible={this.state.visible}      
          onCancel={this.handleCancel}    
          footer={null}
        >
          {<TimesheetUploadsContainer timesheet={this.state.currentTimesheet} readonly />}
        </Modal>}
      </div>
    );
  }
}

TimesheetList.defaultProps = {
  timesheets: null,
  user: null,
};

TimesheetList.propTypes = {
  timesheets: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  ),
  user: PropTypes.shape({
    emails: PropTypes.array.isRequired,
  }),
  loading: PropTypes.bool.isRequired,
};

export default TimesheetList;
