import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';
import { UPDATE_DELIVERABLE } from './mutation';
import { showError, showSuccess } from '../../notifications';
import { useGlobal } from 'reactn';
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const dateFormat = 'MM/DD/YYYY';

const UpdateDeliverableForm = ({
  onComplete,
  client,
  projectId,
  deliverable = {},
  refetchQueries = [],
}) => {
  const [hasError, setHasError] = useState(false);
  const [user] = useGlobal('user');
  const financeGroupInc = user.menu.filter(item => item.label === 'Finance');
  return (
    <Formik
      initialValues={{
        ...deliverable,
      }}
      validate={values => {
        const errors = {};
        // console.log({ values });
        // if (!values.password) {
        //   !setEmailCheck.isSpruceEmail
        //     ? (errors.password = 'Password is definitely required')
        //     : (errors.password = "I can't let you do this");
        // }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (deliverable._id) {
          return client
            .mutate({
              mutation: UPDATE_DELIVERABLE,
              variables: {
                id: deliverable._id,
                ...values,
              },
              awaitRefetchQueries: true,
              refetchQueries,
            })
            .then(() => {
              setSubmitting(false);
              onComplete();
              showSuccess({ message: `Successfully updated deliverable.` })
            })
            .catch(err => {
              // console.log('Error Message: ', err.message);
              showError({ message: err.message })
              setHasError(true);
            });
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => {
        const { accountingCodeSuffix, deliverableName, milestoneName, accountingCode } = values;
        return (
          <Form onSubmit={handleSubmit} {...formItemLayout}>
            <Form.Item label="Deliverable Name" hasFeedback>
              <Input name="deliverableName" onChange={handleChange} value={deliverableName} />
            </Form.Item>
            <Form.Item label="Milestone Name" hasFeedback>
              <Input name="milestoneName" onChange={handleChange} value={milestoneName} />
            </Form.Item>
            {financeGroupInc && financeGroupInc.length ? (
              <>
                <Form.Item label="BU Code" hasFeedback>
                  <Input name="accountingCode" onChange={handleChange} value={accountingCode} />
                </Form.Item>
                <Form.Item label="IS Code" hasFeedback>
                  <Input
                    name="accountingCodeSuffix"
                    onChange={handleChange}
                    value={accountingCodeSuffix}
                  />
                </Form.Item>
              </>
            ) : (
              <></>
            )}

            <div className="flex justify-end">
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                {deliverable._id ? 'Update Deliverable' : ''}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withApollo(UpdateDeliverableForm);
