import { BlobServiceClient } from '@azure/storage-blob';
import { get } from 'lodash';

// eslint-disable-next-line
export const getColorByStatus = status => {
  switch (status) {
    case 'Submitted-Pending':
      return '#F2C94C';
    case 'Declined':
      return '#EB5757';
    case 'Approved':
      return '#27AE60';
    default:
      return '#828282';
  }
};

export const capitalize = s => get(s, '[0]') && get(s, '[0]').toUpperCase() + s.slice(1);

export async function uploadFileToAzureBlob({ fileName, file, blobContainerName }) {
  const { REACT_APP_AZURE_STORAGE_ACCOUNT, REACT_APP_AZURE_STORAGE_SAS } = process.env;
  const storageUrl = `https://${REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net`;
  const blobServiceClient = new BlobServiceClient(
    `https://${REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net${REACT_APP_AZURE_STORAGE_SAS}`,
  );
  const containerClient = blobServiceClient.getContainerClient(blobContainerName);
  const blockBlobClient = containerClient.getBlockBlobClient(fileName);
  await blockBlobClient.uploadBrowserData(file);
  return `${storageUrl}/${blobContainerName}/${fileName}`;
}
