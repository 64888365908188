import React, { useState } from 'react';
import styled from 'styled-components';
import randomcolor from 'randomcolor';
import Trianglify from 'react-trianglify';
import Card from '../Card';
import Button from '../Button';
import Modal from '../Modal';
import { Query } from '@apollo/react-components';
import { Icon } from 'antd';
import { GET_END_CLIENTS } from './queries';
import history from '../../../../api/redux/history';
import UpdateProjectForm from '../UpdateProjectForm/UpdateProjectForm';

const Wrapper = styled.div``;
const Header = styled.div`
  height: 100px;
  background: ${props => props.background};
  position: relative;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;
const ClientName = styled.h2`
  position: absolute;
  bottom: -0.5rem;
  left: 0.5rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  background: white;
  padding: 1rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;
const ProjectDescription = styled.p`
  color: initial;
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`;

const ProjectCard = ({ project, truncate = false, liftOnHover = false, refetchQueries }) => {
  const [showModal, setShowModal] = useState(false);
  const { location } = history;
  const { pathname } = location;
  return (
    <Card
      withShadow
      liftOnHover={liftOnHover}
      headerComponent={
        <Header background={randomcolor({ seed: project._id })}>
          <Trianglify
            cell_size={300}
            output="svg"
            seed={project._id}
            width={1150}
            height={500}
            x_colors={[
              randomcolor({ seed: project._id, luminosity: 'light' }),
              randomcolor({ seed: project._id, luminosity: 'dark' }),
            ]}
          />
          <ClientName>{project.client?.shortName}</ClientName>
        </Header>
      }
      padded
    >
      <div className="h-40">
        <h2>
          {project.name?.length > 50 && truncate
            ? project.name.substring(0, 50 - 3) + '...'
            : project.name}
        </h2>
        <ProjectDescription>
          {project.description?.length > 150 && truncate
            ? project.description.substring(0, 150 - 3) + '...'
            : project.description}
        </ProjectDescription>
      </div>
      <div style={{float:"right"}}>
        {pathname.includes('/project-management/projects/' + project._id) ? (
          <Button  onClick={() => setShowModal(true)}>
            Update Project Details
          </Button>
        ) : (
          ''
        )}
      </div>
      <div>
        <Modal
          title="Update Project"
          visible={showModal}
          destroyOnClose
          onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          footer={null}
        >
          <Query query={GET_END_CLIENTS}>
            {({ loading, error, data }) => {
              if (error) throw new Error(error);
              const allEndClients = data && data.allEndClients ? data.allEndClients : [];
              return (
                <UpdateProjectForm
                  projectId={project._id}
                  onComplete={() => setShowModal(false)}
                  data={allEndClients}
                  currentProject={project}
                  refetchQueries={refetchQueries}
                />
              );
            }}
          </Query>
        </Modal>
      </div>
    </Card>
  );
};

export default ProjectCard;
