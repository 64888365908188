import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TopAvatarMenu from './TopAvatarMenu';
import Icon from '../../common/components/Icon';

const CustomHeader = styled.div`
  z-index: 2;
  padding: 0 16px;
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > *:last-child {
    margin-left: auto;
  }
`;

const Logo = styled.img`
  height: 35px;
`;

const LogoWrapper = styled.div`
  width: ${props => (props.sidebarCollapsed ? '150px' : '250px')};
  display: flex;
  justify-content: flex-start;
  transition: all 200ms 300ms;
`;

const StyledIcon = styled(({ sidebarCollapsed, ...rest }) => <Icon {...rest} />)`
  font-size: 25px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 200ms;
  ${props => props.sidebarCollapsed && 'transition: opacity 200ms 300ms; opacity: 1;'};
`;

const TopBar = ({ toggleSidebar, sidebarCollapsed }) => (
  <CustomHeader>
    <LogoWrapper sidebarCollapsed={sidebarCollapsed}>
    <Link to="/user">
      <Logo src="/logo-wide.png" alt="Logo" />
    </Link>
    </LogoWrapper>
    <StyledIcon
      sidebarCollapsed={sidebarCollapsed}
      onClick={toggleSidebar}
      type={sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
    />
    <TopAvatarMenu />
  </CustomHeader>
);

export default TopBar;
