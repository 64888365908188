import gql from 'graphql-tag';

export const CREATE_TIMESHEET = gql`
  mutation CreateTimesheet($endDate: String!,$user: String) {
    createTimesheet(input: { endDate: $endDate , user: $user })
  }
`;

export const SUBMIT_TIMESHEET = gql`
  mutation SubmitTimesheet($timesheetId: String!) {
    submitTimesheet(input: { timesheetId: $timesheetId })
  }
`;

export const RECALL_TIMESHEET = gql`
  mutation RecallTimesheet($timesheetId: String!) {
    recallTimesheet(input: { timesheetId: $timesheetId })
  }
`;

export const CANCEL_TIMESHEET = gql`
  mutation CancelTimesheet($timesheetId: String!) {
    cancelTimesheet(input: { timesheetId: $timesheetId })
  }
`;

export const REMOVE_TIMESHEET_LINEITEM = gql`
  mutation RemoveTimesheetLineItem($timesheetId: String!, $lineItemIndex: Int!, $user:String) {
    removeTimesheetLineItem(input: { timesheetId: $timesheetId, lineItemIndex: $lineItemIndex , user : $user })
  }
`;

export const ADD_TIMESHEET_LINEITEM = gql`
  mutation AddTimesheetLineItem(
    $timesheetId: String!
    $deliverableId: String!
    $projectId: String!
    $user: String
  ) {
    addTimesheetLineItem(
      input: { timesheetId: $timesheetId, deliverableId: $deliverableId, projectId: $projectId, user: $user }
    )
  }
`;

export const PREFILL_ADD_TIMESHEET_LINEITEM = gql`
  mutation prefillAddTimesheetLineItem(
    $groupedLineItems: [String]!
    $timesheetId: String!
  ) {
    prefillAddTimesheetLineItem(timesheetId: $timesheetId, groupedLineItems: $groupedLineItems )
  }
`;

export const UPDATE_HOURS_MUTATION = gql`
  mutation UpdateHours($taskId: String!, $hours: Float) {
    updateHours(input: { taskId: $taskId, hours: $hours })
  }
`;

export const UPDATE_TIMESHEET_TASK_COMMENT = gql`
  mutation UpdateTimesheetTaskComment($taskId: String!, $comment: String) {
    updateTimesheetTaskComment(input: { taskId: $taskId, comment: $comment })
  }
`;

export const ADD_HOURS_MUTATION = gql`
  mutation AddHours(
    $projectId: String!
    $timesheetId: String!
    $deliverableId: String!
    $hours: Float!
    $date: String!
    $user:  String
  ) {
    addHours(
      input: {
        projectId: $projectId
        timesheetId: $timesheetId
        deliverableId: $deliverableId
        hours: $hours
        date: $date
        user: $user
      }
    )
  }
`;

export const UPLOAD_TIMESHEET_ATTACHMENT = gql`
  mutation TimesheetAttachment($timeSheetId:String,$url:String,$description:String) {
    timesheetAttachment(timeSheetId:$timeSheetId,url:$url,description:$description)
  }
`;
export const DELETE_TIMESHEET_ATTACHMENT = gql`
  mutation DeleteTimesheetAttachment($timeSheetId:String,$id:String) {
    deleteTimesheetAttachment(timeSheetId:$timeSheetId,id:$id)
  }
`;

export const UPLOAD_TIMESHEET_EXPENSE = gql`
  mutation TimesheetExpenses($timeSheetId:String,$url:String,$description:String,$amount:Float) {
    timesheetExpenses(timeSheetId:$timeSheetId,url:$url,description:$description,amount:$amount)
  }
`;

export const DELETE_TIMESHEET_EXPENSE = gql`
  mutation DeleteTimesheetExpenses($timeSheetId:String,$id:String) {
    deleteTimesheetExpenses(timeSheetId:$timeSheetId,id:$id)
  }
`;