import React from 'reactn';
import { getGlobal } from 'reactn';
import styled from 'styled-components';
import Modal from '../../common/components/Modal';
import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';
import TimesheetContainer from './TimesheetContainer';
import PopoverDecline from './DeclinePopover';
import {
  APPROVE_TIMESHEET_TIMESHEET_LINEITEM,
  REJECT_TIMESHEET_TIMESHEET_LINEITEM,
  CANCEL_TIMESHEET_TIMESHEET_LINEITEM,
} from '../mutations';
import {
  GET_TIMESHEET_BY_ID,
  GET_TIMESHEETS_FOR_APPROVAL,
  GET_APPROVED_TIMESHEETS,
} from '../queries';
import TimesheetUploadsContainer from '../../timesheets/components/TimesheetUploadsContainer';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
  > * {
    margin: 0 0.5em;
  }
`;

// given user and array of remaining approvers, return bool of where user needs to approve ts
const canCurrentUserApprove = (user, remainingEmailsThatCanApprove) =>
  remainingEmailsThatCanApprove.includes(user.emails[0].address) ||
  remainingEmailsThatCanApprove.includes(user.emails[0].address.toLowerCase());

// determine if ts buttons should be disabled
const shouldButtonBeDisabled = (timesheet, loading, user) => {
  const { status } = timesheet;
  // if status of ts is approved or declined, disabled buttons
  if (status === 'Approved') {
    return true;
  }

  // if buttons are in loading state, disable them
  if (loading) {
    return true;
  }

  // flatten all required approver emails
  const remainingEmailsThatCanApprove = timesheet.approvalsRequired.reduce((acc, cur) => {
    if (cur.status && cur.status === 'Approved') {
      // ignore
      return acc;
    }
    return [...acc, ...cur.canApprove];
  }, []);

  // if user is NOT required to approve ts, disable buttons
  if (!canCurrentUserApprove(user, remainingEmailsThatCanApprove)) {
    return true;
  }

  return false;
};

class TimesheetActions extends React.Component {
  state = {
    approveLoading: false,
    declineLoading: false,
    cancelLoading: false,
    visible: false,
    viewAttachment: false,
  };

  decline = comments => {
    this.setState({ declineLoading: true });
    const { client, timesheet } = this.props;
    return client
      .mutate({
        mutation: REJECT_TIMESHEET_TIMESHEET_LINEITEM,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_TIMESHEETS_FOR_APPROVAL,
          },
          {
            query: GET_TIMESHEET_BY_ID,
            variables: {
              timesheetId: timesheet._id,
            },
          },
        ],
        variables: {
          timesheetId: timesheet._id,
          comments,
        },
      })
      .then(() => this.setState({ declineLoading: false }));
  };

  // cancel timesheet
  cancel = comments => {
    const { endDate } = getGlobal();
    this.setState({ cancelLoading: true });
    const { client, timesheet } = this.props;
    client.mutate({
      mutation: CANCEL_TIMESHEET_TIMESHEET_LINEITEM,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_APPROVED_TIMESHEETS,
          variables: {
            endDate,
          },
        },
        {
          query: GET_TIMESHEETS_FOR_APPROVAL,
        },
        {
          query: GET_TIMESHEET_BY_ID,
          variables: {
            timesheetId: timesheet._id,
          },
        },
      ],
      variables: {
        timesheetId: timesheet._id,
        comments,
      },
    });
    // componented will be unmounted so no need to call setState
  };

  approve = () => {
    const { endDate } = getGlobal();
    this.setState({ approveLoading: true });
    const { client, timesheet } = this.props;
    client
      .mutate({
        mutation: APPROVE_TIMESHEET_TIMESHEET_LINEITEM,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_TIMESHEETS_FOR_APPROVAL,
          },
          {
            query: GET_APPROVED_TIMESHEETS,
            variables: {
              endDate,
            },
          },
          {
            query: GET_TIMESHEET_BY_ID,
            variables: {
              timesheetId: timesheet._id,
            },
          },
        ],
        variables: {
          timesheetId: timesheet._id,
        },
      })
      .then(() => {
        const remainingApprovalsRequired = timesheet.approvalsRequired.reduce((acc, cur) => {
          if (cur.status !== 'Approved') {
            return acc + 1;
          }
        }, 0);
        // account for current approval
        if (remainingApprovalsRequired > 1) {
          this.setState({ approveLoading: false });
        }
      });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  showAttachment = () => {
    this.setState({
      viewAttachment: true,
    });
  };
  hideAttachment = () => {
    this.setState({
      viewAttachment: false,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { user } = this.global;
    const { timesheet, showCancelButton } = this.props;
    const { visible, declineLoading, approveLoading, cancelLoading, viewAttachment } = this.state;
    const attachments =
      timesheet.timesheetAttachments?.length || timesheet.timesheetExpense ? true : false;
    return (
      <ActionsWrapper>
        <Tooltip title="View timesheet details" trigger="hover">
          <Button
            onClick={() => {
              this.hideAttachment();
              this.showModal();
            }}
            shape="circle"
            icon="profile"
          />
        </Tooltip>
        <Tooltip
          title={attachments ? 'View timesheet attachments' : 'No attachments'}
          trigger="hover"
        >
          <Button
            onClick={() => {
              this.showAttachment();
              this.showModal();
            }}
            shape="circle"
            icon="paper-clip"
            disabled={!attachments}
          />
        </Tooltip>

        {!showCancelButton && (
          <Tooltip title="Approve" trigger="hover">
            <Button
              loading={approveLoading}
              disabled={shouldButtonBeDisabled(
                timesheet,
                declineLoading || cancelLoading || approveLoading,
                user,
              )}
              shape="circle"
              icon="check"
              onClick={this.approve}
            />
          </Tooltip>
        )}

        <PopoverDecline
          // use cancelPopover props for cancel timesheet option
          btnClassName={showCancelButton ? 'cancel-timesheet' : 'decline-timesheet'}
          cancelPopover={showCancelButton ? 'true' : 'false'}
          loading={showCancelButton ? cancelLoading : declineLoading}
          disabled={shouldButtonBeDisabled(
            timesheet,
            showCancelButton ? cancelLoading : declineLoading || approveLoading,
            user,
          )}
          decline={showCancelButton ? this.cancel : this.decline}
        />

        <Modal
          title={`${timesheet.user.firstName} ${timesheet.user.lastName}`}
          width={1200}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          {viewAttachment && <TimesheetUploadsContainer timesheet={timesheet} readonly />}
          {!viewAttachment && <TimesheetContainer timesheetId={timesheet._id} />}
        </Modal>
      </ActionsWrapper>
    );
  }
}

export default TimesheetActions;
