import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Table from '../../common/components/Table';
import Card from '../../common/components/Card';
import DataDisplay from '../../common/components/DataDisplay';
import ProjectCard from '../../common/components/ProjectCard';

const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
`;

const Cell = styled.div`
  ${props => props.selected && 'font-weight: bold;'};
`;

const renderCell = (value, record) => <Cell selected={record.selected}>{value}</Cell>;

const columns = [
  {
    title: 'Client',
    dataIndex: 'client.shortName',
    key: 'clientName',
    render: renderCell,
  },
  {
    title: 'Project',
    dataIndex: 'name',
    key: 'projectName',
    render: renderCell,
  },
  {
    title: 'Project Code',
    dataIndex: 'projectCode',
    key: 'projectCode',
    render: renderCell,
  },
  {
    title: 'Total Hours',
    dataIndex: 'totalApprovedHours',
    key: 'totalApprovedHours',
    render: renderCell,
  },
  {
    title: 'Resources',
    key: 'resources',
    render: record => (
      <Cell selected={record.selected}>{record.resources && record.resources.length}</Cell>
    ),
  },
  {
    title: 'Deliverables',
    key: 'deliverables',
    render: record => (
      <Cell selected={record.selected}>{record.deliverables && record.deliverables.length}</Cell>
    ),
  },
];

const ProjectList = ({
  projects, loading, history, title,
}) => {
  const { location } = history;
  const { pathname } = location;
  return (
    <Card
      floating
      bodyBackground="none"
      padded={false}
      title={title}
      loading={loading}
      loadingText="Loading Projects"
    >
      <DataDisplay
        data={projects}
        loading={loading}
        itemMinWidth="300px"
        itemMinHeight="200px"
        searchKeys={['name', 'projectCode', 'client.shortName']}
        onGridRender={project => (
          <Link to={`${pathname}/${project._id}`}>
            <ProjectCard project={project} truncate liftOnHover />
          </Link>
        )}
      />
    </Card>
  );
};

export default ProjectList;
