import React, { useState } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import Table from '../../common/components/Table';
import Card from '../../common/components/Card';
import ProjectDetailsDeliverableActions from './ProjectDetailsDeliverableActions';

import { GET_DELIVERABLES_BY_PROJECT_ID } from '../queries';

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
  > * {
    margin: 0 0.5em;
  }
`;
const createColumns = ({ deliverables, refetchQueries }) => [
  {
    title: 'Milestone',
    dataIndex: 'milestoneName',
    key: 'milestoneName',
    filters: deliverables.reduce((acc, cur) => {
      if (acc.find(m => m.text === cur.milestoneName)) return acc;
      return [
        ...acc,
        {
          text: cur.milestoneName,
          value: cur.milestoneName,
        },
      ];
    }, []),
    onFilter: (value, record) => record.milestoneName.indexOf(value) === 0,
  },
  {
    title: 'Deliverable',
    dataIndex: 'deliverableName',
    key: 'deliverableName',
  },
  {
    title: 'Accounting Code',
    dataIndex: 'accountingCode',
    key: 'accountingCode',
  },
  {
    title: 'Accounting Code Suffix',
    dataIndex: 'accountingCodeSuffix',
    key: 'accountingCodeSuffix',
  },
  {
    title: 'Actions',
    key: 'actions',
    width: 200,
    render: data => {
      if (!data.includeInAllProjects) {
        return (
          <ProjectDetailsDeliverableActions deliverable={data} refetchQueries={refetchQueries} />
        );
      }
    },
  },
  ,
];
const ProjectDetailsDeliverableList = ({ projectId, isPaginated = true }) => {
  return (
    <Card>
      <Query query={GET_DELIVERABLES_BY_PROJECT_ID} variables={{ projectId }}>
        {({ loading, error, data }) => {
          if (error) throw new Error(error);
          const deliverables = data?.projectManagement?.project?.deliverables || [];
          const columns = createColumns({
            deliverables,
            refetchQueries: [
              {
                query: GET_DELIVERABLES_BY_PROJECT_ID,
                variables: {
                  projectId,
                },
              },
            ],
          });
          return (
            <Table
              // footer={data => (
              //   <ActionWrapper>
              //     <Button icon="plus" disabled>
              //       Add Deliverable
              //     </Button>
              //   </ActionWrapper>
              // )}
              columns={columns}
              pagination={isPaginated}
              loading={loading}
              dataSource={
                // make sure deliverables exist and arrived to browser
                deliverables &&
                deliverables
                  .map(d => ({ ...d, key: d._id }))
                  .sort((a, b) => {
                    const milestone1 = a.milestoneName.toUpperCase();
                    const milestone2 = b.milestoneName.toUpperCase();
                    if (milestone1 < milestone2) {
                      return -1;
                    }
                    if (milestone1 > milestone2) {
                      return 1;
                    }
                    return 0;
                  })
              }
            />
          );
        }}
      </Query>
    </Card>
  );
};

export default ProjectDetailsDeliverableList;

// const subTableColumns = [
//   {
//     title: 'Resource',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Email',
//     dataIndex: 'email',
//     key: 'email',
//   },
//   {
//     title: 'Approved Hours',
//     dataIndex: 'totalApprovedHours',
//     key: 'totalApprovedHours',
//   },
// ];
