import React from 'react'
import TimesheetReminderCronConfig from './TimesheetReminderCronConfig'

const CronConfigContainer = ({weeklyReminderEmailJob}) => {
    return (
        <div>
            <TimesheetReminderCronConfig weeklyReminderEmailJob={weeklyReminderEmailJob}/>
        </div>  
    )
}

export default CronConfigContainer
