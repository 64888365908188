import React from 'react';
import styled from 'styled-components';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import Tabs, { TabPane } from '../../common/components/Tabs';
import Card from '../../common/components/Card';
import TimesheetApprovalListCard from './TimesheetApprovalListCard';
import ProjectManagement from './ProjectManagement';
import ProjectDetailsContainer from './ProjectDetailsContainer';

// need router history to change url on tab change
const ProjectManagerPage = ({ history, location }) => {
  const splitPathname = location.pathname.split('/');
  const activeKey = splitPathname[2];
  return (
    <Switch>
      <Route
        exact
        path="/project-management"
        render={() => <Redirect to="/project-management/timesheets" />}
      />
      <Route
        path="/project-management/timesheets/:endDate"
        render={() => <TimesheetApprovalListCard />}
      />
      <Route path="/project-management/timesheets" render={() => <TimesheetApprovalListCard />} />
      <Route exact path="/project-management/projects" render={() => <ProjectManagement />} />
      <Route
        path="/project-management/projects/:projectId/:activeTab"
        render={() => <ProjectDetailsContainer />}
      />
      <Route
        path="/project-management/projects/:projectId"
        render={() => <ProjectDetailsContainer />}
      />
    </Switch>
  );
};

export default withRouter(ProjectManagerPage);
