import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { useGlobal } from 'reactn';
import { flatten } from 'lodash';
import gql from 'graphql-tag';
import numeral from 'numeral';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import Button from '../../common/components/Button';
import PayrollAllocationReportGrid from './PayrollAllocationReportGrid';
import { sumLineItemHours } from '../../project-manager/components/TimesheetSnapshotContainer';

export const PAYROLL_ALLOCATION_QUERY = gql`
  query getTimesheetWeeklySnapshot($startDate: String, $endDate: String) {
    finance {
      timesheetSnapshots(startDate: $startDate, endDate: $endDate)
    }
  }
`;

let exportFunc;

const handleExportClick = () => exportFunc();

const onExportReady = ({ getExportFunc }) => {
  exportFunc = getExportFunc();
};

const constructGridData = data => {
  const timesheets = data?.finance?.timesheetSnapshots.reduce((acc, cur) => {
    const email = cur.user.emails[0].address;

    const projects = cur.lineItems
      .reduce((accL, curL) => {
        const totalLineItemHours = sumLineItemHours(curL);
        const existingProject = accL.find(p => p.name === curL.project.name);
        if (existingProject) {
          existingProject.totalLineItemHours += totalLineItemHours;
          return accL;
        }
        return [
          ...accL,
          {
            ...curL.project,
            totalLineItemHours,
          },
        ];
      }, [])
      .map(p => ({
        ...p,
        allocation: p.totalLineItemHours / cur.totalHours,
      }));

    return {
      ...acc,
      [email]: {
        ...cur,
        projects,
      },
    };
  }, {});

  let gridRows = [];
  for (const key in timesheets) {
    const ts = timesheets[key];
    const totalByLineItems = timesheets[key].projects.reduce(
      (acc, cur) => acc + cur.totalLineItemHours,
      0,
    );

    ts.projects.forEach(p => {
      gridRows.push({
        firstName: ts.user.firstName,
        lastName: ts.user.lastName,
        email: ts.user.emails[0].address,
        payrollId: ts.user.payrollId,
        totalHours: p.totalLineItemHours,
        allocation: numeral(p.totalLineItemHours / totalByLineItems).format('0.00%'),
        project: p.name,
        bu: `${p.accountingCode || ''}-${p.accountingCodeSuffix || ''}`,
      });
    });
  }
  return gridRows;

  return data?.finance?.timesheetSnapshots.map(ts => ({
    firstName: ts.user.firstName,
    lastName: ts.user.lastName,
    email: ts.user.emails[0].address,
    totalHours: ts.totalHours,
  }));
};

const PayrollAllocationReport = () => {
  const [endDate, setEndDate] = useGlobal('endDate');
  const { loading, data } = useQuery(PAYROLL_ALLOCATION_QUERY, {
    variables: {
      startDate: moment(endDate).subtract(7, 'days').format('YYYYMMDD'),
      endDate,
    },
  });
  const gridData = constructGridData(data);

  return (
    <Card
      title="Payroll Allocation"
      floating
      padded={false}
      actionComponent={
        <div className="flex">
          <WeekPicker
            loading={loading}
            endDate={moment(endDate).format('YYYYMMDD')}
            onDateChange={endDate => {
              const endDateString = moment(endDate).format('YYYYMMDD');
              setEndDate(endDateString);
            }}
          />
          <div className="pl-2">
            <Button onClick={handleExportClick}>Export</Button>
          </div>
        </div>
      }
    >
      <PayrollAllocationReportGrid onExportReady={onExportReady} rowData={gridData} />
    </Card>
  );
};

export default PayrollAllocationReport;
