import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Modal from '../../common/components/Modal';
import Button from '../../common/components/Button';
import UpdateDeliverableForm from '../../common/components/UpdateDeliverableForm';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ProjectDetailsDeliverableActions = ({ deliverable, refetchQueries }) => {
  const [updateDeliverableVisible, setUpdateDeliverableVisible] = useState(false);
//   const [isRemoving, setIsRemoving] = useState(false);
  return (
    <ActionsWrapper>
      <div className="flex">
        <Button onClick={() => setUpdateDeliverableVisible(true)}>Edit</Button>
        
      </div>
      <Modal
        title="Update Deliverable"
        visible={updateDeliverableVisible}
        destroyOnClose
        onOk={() => setUpdateDeliverableVisible(false)}
        onCancel={() => setUpdateDeliverableVisible(false)}
        footer={null}
      >
        <UpdateDeliverableForm
          deliverable={deliverable}
          refetchQueries={refetchQueries}
          onComplete={() => setUpdateDeliverableVisible(false)}
        />
      </Modal>
    </ActionsWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.meteor?.user,
});

export default connect(mapStateToProps)(ProjectDetailsDeliverableActions);
