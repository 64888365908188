import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

const Wrapper = styled.div`
  height: 700px;
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

class TimesheetWeeklySnapshot extends React.Component {
  gridApi = undefined;

  onGridReady = params => {
    this.gridApi = params.api;
    const { onExportReady } = this.props;
    onExportReady({ getExportFunc: () => this.export });
  };

  export = () => {
    const { endDate } = this.props;
    const params = {
      fileName: `Spruce-TimesheetSnapshot-WeekEnding(${endDate})-ExportedOn(${moment(
        new Date(),
      ).format('YYYYMMDD-hhmm')}).csv`,
    };
    this.gridApi.exportDataAsCsv(params);
  };

  render() {
    const { rowData } = this.props;
    return (
      <Wrapper className="ag-theme-balham">
        <AgGridReact
          modules={AllCommunityModules}
          onGridReady={this.onGridReady}
          rowData={rowData}
          enableCellTextSelection
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
          }}
        >
          <AgGridColumn field="endDate" width={125} />
          <AgGridColumn field="status" width={175} />
          <AgGridColumn field="lastName" />
          <AgGridColumn field="firstName" />
          <AgGridColumn field="payrollId" headerName="Payroll ID" />
          <AgGridColumn field="email" />
          <AgGridColumn field="clientName" width={150} />
          <AgGridColumn field="project" />
          <AgGridColumn field="bu" headerName="BU - IS" />
          <AgGridColumn field="milestone" />
          <AgGridColumn field="deliverable" />
          <AgGridColumn field="mon" width={75} />
          <AgGridColumn field="tue" width={75} />
          <AgGridColumn field="wed" width={75} />
          <AgGridColumn field="thu" width={75} />
          <AgGridColumn field="fri" width={75} />
          <AgGridColumn field="sat" width={75} />
          <AgGridColumn field="sun" width={75} />
          <AgGridColumn field="total" width={75} />
          {/* <AgGridColumn field="comment" /> */}
        </AgGridReact>
      </Wrapper>
    );
  }
}

export default withRouter(TimesheetWeeklySnapshot);
