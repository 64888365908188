import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { capitalize, groupBy, map, uniqBy } from 'lodash';
import { Dropdown, Button, Menu } from 'antd';

const Wrapper = styled.div``;

const Section = styled.div`
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Heading = styled.div`
  font-weight: 800;
  font-size: 1.2em;
`;
const Logo = styled.img`
  height: 35px;
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  transition: all 200ms ease-in-out;
`;

const Table = styled.table`
  height: 100%;
  width: 100%;
  border: 2px solid black;
`;

const Thead = styled.thead`
  border: 1px solid black;
`;

const Tbody = styled.tbody``;

const Tfoot = styled.tfoot`
  border: 2px solid black;
`;

const Tr = styled.tr`
  min-height: 35px;
  height: ${props => (props.height ? props.height : '35px')};
  border: 1px solid black;

  ${props =>
    props.dark &&
    `
  background: #32547A;
  color: white;
  font-weight: 500;
   `};
`;

const Th = styled.th`
  border: 1px solid black;
  width: ${props => (props.width ? props.width : 'initial')};
  text-align: ${props => (props.align ? props.align : 'initial')};
  font-weight: ${props => (props.bold ? '800' : 'initial')};
`;

const Td = styled.td`
  border: 1px solid black;
  width: ${props => (props.width ? props.width : 'initial')};
  text-align: ${props => (props.align ? props.align : 'initial')};
  font-weight: ${props => (props.bold ? '800' : 'initial')};
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25em;
`;

const CellTitle = styled.div`
  font-weight: 800;
`;

const CommentLine = styled.span``;
const Approvals = styled.div``;

export default ({ timesheet, queryParams }) => {
  const { user, endDate, status, approvalsRequired } = timesheet;
  const { options } = queryParams;
  const [showFilter, setShowFilter] = useState(options?.includes('project-filter'));

  const projects = uniqBy(
    ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].reduce((acc, day) => {
      const dailyProjects = timesheet.week[day].map(t => t.project);
      return [...acc, ...dailyProjects];
    }, []),
    p => p._id,
  );

  return (
    <Wrapper>
      {showFilter && (
        <div className="w-full py-4 flex justify-between">
          <Dropdown
            overlay={
              <Menu onClick={null}>
                {projects.map(p => (
                  <Menu.Item key={p._id}>
                    <Link
                      to={`/reports/timesheets/${timesheet._id}?${queryString.stringify({
                        projectId: p._id,
                      })}`}
                    >
                      {p.name}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button>Filter hours by Project</Button>
          </Dropdown>
          <Button icon="close" onClick={() => setShowFilter(false)}>
            Close Filter
          </Button>
        </div>
      )}
      <Section>
        <Heading>Weekly Timesheet</Heading>
        <LogoWrapper>
          <Logo src="/logo-wide.png" alt="Logo" />
        </LogoWrapper>
      </Section>
      <Table>
        <Thead>
          <Tr height="50px">
            <Th colSpan="3">
              <Cell>
                <CellTitle>Consultant Name</CellTitle>
                <div>{`${user.firstName} ${user.lastName}`}</div>
              </Cell>
            </Th>
            <Th>
              <Cell>
                <CellTitle>For Week Ending (Sunday)</CellTitle>
                <div>{moment(endDate).format('LL')}</div>
              </Cell>
            </Th>
            <Th>
              <Cell>
                <CellTitle>Status</CellTitle>
                <div>{status}</div>
              </Cell>
            </Th>
          </Tr>
          <Tr dark>
            <Th width="75" align="center" bold>
              Day
            </Th>
            <Th width="75" align="center" bold>
              Date
            </Th>
            <Th width="75" align="center" bold>
              Hours
            </Th>
            <Th width="750px" align="center" colSpan="2" bold>
              Activities, Accomplishments, & Comments
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, index) => {
            const date = moment(endDate)
              .subtract(6 - index, 'days')
              .format('MM/DD/YY');
            const dailyTasks = timesheet.week[day];
            const totalDailyHours = dailyTasks.reduce((acc, cur) => {
              const { ignoreHoursInTotal } = cur.deliverable;
              if (ignoreHoursInTotal) {
                return acc;
              }
              return acc + cur.hours;
            }, 0);
            const groupedTasksByProject = groupBy(dailyTasks, 'project.name');

            return (
              <Tr key={day}>
                <Td align="center">{capitalize(day)}</Td>
                <Td align="center">{date}</Td>
                <Td align="center">{totalDailyHours}</Td>
                <Td colSpan="2">
                  {map(groupedTasksByProject, (projectTasks, projectName) => (
                    <Cell key={projectName}>
                      <span>
                        <strong>{projectName}</strong>
                      </span>
                      {projectTasks.map(task => {
                        const { hours, comment, deliverable } = task;
                        const { deliverableName, milestoneName } = deliverable;
                        return (
                          <Cell key={task._id}>
                            <CommentLine>
                              <span>
                                {`${hours} hours on ${deliverableName} (${milestoneName}). `}
                              </span>
                              {comment && <span>{comment}</span>}
                            </CommentLine>
                          </Cell>
                        );
                      })}
                    </Cell>
                  ))}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td align="center" colSpan="2" bold>
              Total
            </Td>
            <Td align="center" bold>
              {timesheet.totalHours}
            </Td>
            <Td colSpan="2" />
          </Tr>
        </Tfoot>
      </Table>
      <Section>
        <div>
          Project managers agree that by approving timesheet, he/she confirms the quantity of time
          worked by the Spruce representative is correct and the quality of work is acceptable.
        </div>
      </Section>
      <Section>
        <Approvals>
          <Heading>Timesheet approved by:</Heading>
          {approvalsRequired?.map(approval => {
            if (approval.status === 'Approved') {
              const { firstName, lastName } = approval.approvedByUser;
              return (
                <div key={approval.approvedOn + firstName}>
                  {`${firstName} ${lastName} on ${moment(approval.approvedOn).format('LLL')}`}
                </div>
              );
            }
          })}
        </Approvals>
      </Section>
      <Section>
        <Approvals>
          <Heading>Supervisor/Client:</Heading>
        </Approvals>
      </Section>
      <Section>
        <Approvals>
          <Heading>Date:</Heading>
        </Approvals>
      </Section>
    </Wrapper>
  );
};
