import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import moment from 'moment';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import TimesheetContainer from './TimesheetContainer';
import TimesheetUploadsContainer from './TimesheetUploadsContainer';
import { Query } from 'react-apollo';
import TimesheetLoading from './TimesheetLoading';
import { GET_USER_TIMESHEET } from '../queries';

const Message = styled.div`
  color: red;
  font-size: 1.2em;
`;

class TimesheetCard extends React.Component {
  changeUrl = endDate => this.props.history.push(`/user/timesheets/${endDate}`);

  componentDidMount() {
    const paramsEndDate = get(this.props, 'match.params.endDate');
    // if url endDate is not specified in URL, then redirect to current weeks endDate
    if (paramsEndDate === 'create' || !paramsEndDate) {
      this.props.history.replace(`/user/timesheets/${this.props.endDate}`);
    } else if (this.props.endDate !== paramsEndDate) {
      // if url endDate does not match to redux state endDate, update state to match URL endDate
      const currentEndDate = moment(paramsEndDate).endOf('isoWeek');
      this.props.changeTimesheetDate(currentEndDate.format('YYYYMMDD'));
      this.props.history.replace(`/user/timesheets/${currentEndDate.format('YYYYMMDD')}`);
    }
  }

  render() {
    return (
      <Query query={GET_USER_TIMESHEET} variables={{ endDate: this.props.endDate, user: null }}>
        {({ loading, error, data }) => {
          if (error) return <div>error</div>;
          if (loading || !data) return <TimesheetLoading />;
          return (
            <>
              <Message>
                <strong>ATTENTION:</strong> Our timesheet portal has been upgraded. Please log your
                time in the new portal at{' '}
                <a href="https://portal-new.sprucetech.com">https://portal-new.sprucetech.com</a>.
                The old timesheet portal will no longer accept time entries effective 9/18/23. Thank
                you for your cooperation!
              </Message>
              <Card
                title="Weekly Timesheet"
                padded={false}
                floating
                withShadow
                actionComponent={
                  <WeekPicker
                    endDate={this.props.endDate}
                    onDateChange={endDate => {
                      const endDateString = moment(endDate).format('YYYYMMDD');
                      this.changeUrl(endDateString);
                      this.props.changeTimesheetDate(endDateString);
                    }}
                  />
                }
              >
                <TimesheetContainer timesheet={data.timesheet} endDate={this.props.endDate} />
              </Card>
              <Card title=" " padded={false} floating withShadow>
                <TimesheetUploadsContainer
                  timesheet={data.timesheet}
                  endDate={this.props.endDate}
                />
              </Card>
            </>
          );
        }}
      </Query>
    );
  }
}

export default TimesheetCard;
