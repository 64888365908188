import gql from 'graphql-tag';

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $projectId: String!
    $clientId: String!
    $clientName: String!
    $clientShortName: String!
    $name: String!
    $description: String!
  ) {
    updateProject(
      projectId: $projectId
      clientId: $clientId
      clientName: $clientName
      clientShortName: $clientShortName
      name: $name
      description: $description
    )
  }
`;
export const CREATE_ENDCLIENT = gql`
  mutation createEndClient(
    $projectId: String!
    $clientName: String!
    $clientShortName: String!
    $name: String!
    $description: String!
  ) {
    addEndClient(
      projectId: $projectId
      clientName: $clientName
      clientShortName: $clientShortName
      name: $name
      description: $description
    )
  }
`;
