import React from 'react';
import Card from '../../common/components/Card';
import moment from 'moment';
import FullyVaccinatedReport from './FullyVaccinatedReport';
import NotVaccinatedReport from './NotVaccinatedReport';
import NotRespondedReport from './NotRespondedReport';

const getFullNameofVaccine = vaccineName => {
  if (vaccineName == 'pfizer') {
    return 'Pfizer';
  } else if (vaccineName == 'moderna') {
    return 'Moderna';
  } else if (vaccineName == 'jhonsonAndJhonson') {
    return 'Jhonson And Jhonson';
  } else if (vaccineName == 'astraZenecavaxzevria') {
    return 'AstraZeneca Vaxzevria';
  } else if (vaccineName == 'siiCovishield') {
    return 'Serum Institute of India COVISHIELD';
  } else if (vaccineName == 'sinopharmBibpCovilo') {
    return 'SinoPharm/BIBP Covilo';
  } else if (vaccineName == 'sinovacCovac') {
    return 'Sinovac CoronaVac';
  }
};

const lastTest = testArr => {
  return testArr ? testArr.sort((a, b) => b.date - a.date) : [];
};

const getNoType = (type)=>{
  if(!type || type === "intendToVaccinated") return "Vaccination Intended"
  if(type === "notIntendToVaccinated") return "Vaccination Not Intended Before January 18,2022"
  if(type === "reqestingException") return "Exemption Requested"
}

const VaccineReport = ({ data }) => {
  const { vaccineTrackerReport } = data;
  const fullyVaccinated = [];
  const notVaccinated = [];
  const notResponding = [];
  const notVaccinatedUserId = []
  vaccineTrackerReport &&
    vaccineTrackerReport.filter(item => {
      if (item.vaccineData?.length && item.vaccineStatus === 'yes') {
        fullyVaccinated.push({
          employee: item.firstName + ' ' + item.lastName,
          vaccine: item.vaccineData[0].vaccineDetails
            ? getFullNameofVaccine(item.vaccineData[0].vaccineDetails.vaccineVersion)
            : '',
          vaccineDate: item.vaccineData[0].vaccineDetails
            ? item.vaccineData[0].vaccineDetails.lastVaccinationDate
            : '',
          vaccineProof: item.vaccineData[0].vaccineDetails
            ? item.vaccineData[0].vaccineDetails.vaccineProof
            : '',
        });
      } else if (item.vaccineData?.length && item.vaccineStatus === 'no') {
        if (
          item.vaccineData[0]?.testDetails 
        ) {
          const sortedTests = lastTest(item.vaccineData[0]?.testDetails?.testProof);

          const noType = getNoType(item.vaccineData[0]?.noType);
          
          notVaccinatedUserId.push(item._id);
          notVaccinated.push({
            id:item._id,
            employee: item.firstName + ' ' + item.lastName,
            test: sortedTests && sortedTests.length ? sortedTests[0]?.proofUrl : '',
            // project,
            // client,
            status:noType,
            lastDate:
              sortedTests && sortedTests.length ? moment(sortedTests[0]?.date).format('L') : '',
          });
        }
      } else {
        notResponding.push({
          employee: item.firstName + ' ' + item.lastName,
          type: item.employeeType ? item.employeeType : '',
          lastLogin: item.lastActiveOn ? moment(item.lastActiveOn).format('L') : '',
        });
      }
    });

  return (
    <Card padded={false} floating title="Vaccine Tracker Report" bodyBackground="none">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 p-2">
          <FullyVaccinatedReport title="Fully Vaccinated" data={fullyVaccinated} />
        </div>

        <div className="w-full md:w-1/2 p-2">
          <NotRespondedReport title="Not Responded" data={notResponding} />
        </div>

        <div className="w-full p-2">
          <NotVaccinatedReport title="Not Vaccinated" notVaccinated={notVaccinated} notVaccinatedUserId={notVaccinatedUserId}  />
        </div>
      </div>
    </Card>
  );
};

export default VaccineReport;
