import React, { useState } from 'react';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import Input from '../Input';
import Card from '../Card';
import Table from '../Table';
import Button from '../Button';

const { Search } = Input;
const ButtonGroup = Button.Group;

const GridFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const GridItem = styled.div`
  margin: 0.5rem;
  flex: 1;
  display: flex;
  align-items: stretch;
  ${props => props.minWidth && `min-width: ${props.minWidth};`};
  ${props => props.minHeight && `min-height: ${props.minHeight};`};
`;
const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowItem = styled.div`
  border-bottom: 1px solid #dbdbdb;
  background: white;
  &:last-child {
    border-bottom: none;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 0 0.5rem;
  > * {
    margin: 0 0.5rem;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
`;

const searchData = ({ data, searchIndex, searchKeys, searchString }) => {
  const options = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 10,
    minMatchCharLength: 1,
    keys: searchKeys,
    tokenize: true,
  };
  const fuse = new Fuse(data, options, searchIndex);
  return searchString ? fuse.search(searchString).map(r => r.item) : data;
};

const DataDisplay = ({
  data,
  onGridRender,
  onRowRender,
  itemMinWidth,
  itemMinHeight,
  searchKeys,
  defaultViewType = 'grid',
  onClick,
  loading,
  columns,
  showViewTypeSelector,
  searchPlaceholder = 'Search',
}) => {
  const [viewType, setViewType] = useState(defaultViewType);
  const [searchString, setSearchString] = useState(null);
  const [searchIndex] = useState(searchKeys ? Fuse.createIndex(searchKeys, data) : null);
  const results = searchKeys ? searchData({ data, searchIndex, searchKeys, searchString }) : data;

  return (
    <Card
      loading={loading}
      floating
      bodyBackground="none"
      minHeight="600px"
      actionComponent={
        (searchKeys || showViewTypeSelector) && (
          <ActionWrapper>
            {searchKeys && (
              <Search
                style={{ width: 300 }}
                placeholder={searchPlaceholder}
                onSearch={value => {
                  if (value) {
                    setSearchString(value);
                  } else {
                    setSearchString(null);
                  }
                }}
              />
            )}
            {showViewTypeSelector && (
              <ButtonGroup value={viewType} onChange={e => setViewType(e.target.value)}>
                {onGridRender && (
                  <Button value="grid" icon="appstore" onClick={() => setViewType('grid')} />
                )}
                {onRowRender && (
                  <Button value="list" icon="ordered-list" onClick={() => setViewType('list')} />
                )}
                {columns && (
                  <Button value="table" icon="table" onClick={() => setViewType('table')} />
                )}
              </ButtonGroup>
            )}
          </ActionWrapper>
        )
      }
    >
      <div>
        {results?.length === 0 && <div>No data</div>}
        {viewType === 'grid' && (
          <div className="flex flex-wrap">
            {results.map((item, index) => (
              <div key={index} className="w-1/3 h-full p-2">
                {onGridRender(item)}
              </div>
            ))}
          </div>
        )}
        {viewType === 'list' && (
          <RowFlexContainer>
            {results.map((item, index) => (
              <RowItem key={index} minHeight={itemMinHeight}>
                <Card padded floating width="100%" onClick={onClick}>
                  {onRowRender(item)}
                </Card>
              </RowItem>
            ))}
          </RowFlexContainer>
        )}
        {viewType === 'table' && <Table columns={columns} dataSource={results} />}
      </div>
    </Card>
  );
};

export default DataDisplay;
