import gql from 'graphql-tag';
import { Timesheet } from '../timesheets/queries';

export const GET_DELIVERABLES_BY_PROJECT_ID = gql`
  query getProjectDeliverablesByProjectId($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        deliverables {
          _id
          milestoneName
          deliverableName
          accountingCode
          accountingCodeSuffix
          includeInAllProjects
        }
      }
    }
  }
`;
export const GET_RESOURCES_BY_PROJECT_ID = gql`
  query getProjectResourcesByProjectId($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        resources {
          _id
          name
          email
          role
          startDate
          endDate
          totalApprovedTimesheets
          user {
            imageUrl
            title
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export const GET_TIMESHEETS_BY_PROJECT_ID = gql`
  query getProjectTimesheetsByProjectId(
    $projectId: String
    $startDate: String
    $endDate: String
    $showNotSubmitted: Boolean
  ) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        timesheets(startDate: $startDate, endDate: $endDate, showNotSubmitted: $showNotSubmitted) {
          _id
          lineItems {
            projectId
            totalHours
          }
          approvalsRequired {
            status
            approvers {
              firstName
            }
            approvedOn
            approvedBy
            canApprove
          }
          resource(projectId: $projectId) {
            role
            email
          }
          user {
            _id
            firstName
            lastName
            imageUrl
            title
          }
          status
          totalHours
          endDate
          documentUrl
        }
      }
    }
  }
`;
export const GET_TIMESHEET_SNAPSHOT_BY_PROJECT_ID = gql`
  query getProjectTimesheetSnapshotByProjectId(
    $projectId: String
    $startDate: String
    $endDate: String
    $showNotSubmitted: Boolean
  ) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        timesheetSnapshots(startDate: $startDate, endDate: $endDate, showNotSubmitted: $showNotSubmitted)
      }
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query getProjectById($projectId: String) {
    projectManagement {
      project(projectId: $projectId) {
        _id
        accountingCode
        projectCode
        name
        # totalApprovedHours
        description
        client {
          _id
          shortName
          name
        }
        endClient {
          _id
          shortName
          name
        }
        # resources {
        #   _id
        # }
        # timesheets {
        #   _id
        #   status
        # }
      }
    }
  }
`;

export const GET_TIMESHEET_BY_ID = gql`
  query getTimesheetById($timesheetId: String) {
    projectManagement {
      timesheet(timesheetId: $timesheetId) {
        _id
        status
        totalHours
        startDate
        endDate
        isLocked
        timeline {
          date
          createdBy
          metadata
          type
        }
        lineItems {
          project {
            _id
            name
            client {
              _id
              shortName
            }
          }
          deliverable {
            _id
            deliverableName
            milestoneName
          }
          comment
          monTask {
            ...Weekday
          }
          tueTask {
            ...Weekday
          }
          wedTask {
            ...Weekday
          }
          thuTask {
            ...Weekday
          }
          friTask {
            ...Weekday
          }
          satTask {
            ...Weekday
          }
          sunTask {
            ...Weekday
          }
        }
      }
    }
  }
  ${Timesheet.fragments.weekday}
`;

export const GET_TIMESHEETS_FOR_APPROVAL = gql`
  query getTimesheetsForApproval($startDate: String, $endDate: String) {
    projectManagement {
      timesheets(startDate: $startDate, endDate: $endDate) {
        approvalsRequired {
          status
          approvedOn
          approvedBy
          canApprove
          approvers {
            firstName
            lastName
            imageUrl
          }
        }
        timesheetAttachments {
          _id
          url
          description
        }
        timesheetExpense {
          description
          amount
          attachments {
            _id
            url
          }
        }
        user {
          _id
          firstName
          lastName
          title
          imageUrl
        }
        resource {
          role
          email
        }
        status
        totalHours
        endDate
        userId
        _id
      }
    }
  }
`;

export const GET_APPROVED_TIMESHEETS = gql`
  query getApprovedTimesheets($endDate: String!) {
    projectManagement {
      approvedTimesheets(endDate: $endDate) {
        _id
        endDate
        timesheetAttachments {
          _id
          url
          description
        }
        timesheetExpense {
          description
          amount
          attachments {
            _id
            url
          }
        }
        approvalsRequired {
          status
          approvedOn
          approvedBy
          canApprove
        }
        user {
          _id
          imageUrl
          firstName
          lastName
        }
        resource {
          role
          email
        }
        status
        totalHours
        submittedDate
        approvedDate
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  fragment project on Project {
    _id
    accountingCode
    projectCode
    name
    description
    client {
      _id
      shortName
    }
  }
  query getProjects {
    projectManagement {
      myProjects {
        ...project
      }
      allProjects {
        ...project
      }
    }
  }
`;
