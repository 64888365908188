import Button from 'antd/lib/button';
import styled from 'styled-components';

{/* const StyledButton = styled(({ fullWidth, ...rest }) => <Button {...rest} />)`
  font-size: 1em;
  box-shadow: 0px 1px 2px 1px #e8e8e8;
  ${props => props.fullWidth && 'width: 100%'};
`; */}

const StyledButton = styled(Button)`
  /* font-size: 1em; */
  /* box-shadow: 0px 1px 2px 1px #e8e8e8; */
`;

export default StyledButton;
