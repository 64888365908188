import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import ResourceUtilization from './ResourceUtilization';

const PerformancePage = () => {
  return (
    <Switch>
      <Route exact path="/performance" render={() => <Dashboard />} />
      <Route
        exact
        path="/performance/resource-utilization"
        render={() => <ResourceUtilization />}
      />
    </Switch>
  );
};

export default PerformancePage;
