import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from '../../common/components/Card';

const Wrapper = styled.div`
  color: initial;
`;

const FinanceReports = () => {
  return (
    <Card floating padded={false} bodyBackground="none" title="Reports">
      <div className="flex">
        <Link className="w-1/3 p-2" to="/finance/reports/weekly-snapshot">
          <Card padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Weekly Timesheet Snapshot</h2>
              <p className="text-gray-600">
                Show weekly tasks and hours for all employees for a given range.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className="w-1/3 p-2" to="/finance/reports/daily-snapshot">
          <Card padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Daily Timesheet Snapshot</h2>
              <p className="text-gray-600">
                Show daily tasks and hours for all employees for a given range.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className="w-1/3 p-2" to="/finance/reports/payroll-allocation">
          <Card padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className="text-gray-800">Payroll Allocation</h2>
              <p className="text-gray-600">
                Generate employee allocation report for payroll submission.
              </p>
            </Wrapper>
          </Card>
        </Link>
      </div>
    </Card>
  );
};

export default FinanceReports;
