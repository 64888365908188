import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Card from '../../common/components/Card';

const GET_USER_TIMESHEETS = gql`
  query getUserTimesheets($userId: String!) {
    user(userId: $userId) {
      timesheets {
        status
      }
    }
  }
`;

const Timesheets = ({ userId }) => {
  const { loading, data } = useQuery(GET_USER_TIMESHEETS, { variables: { userId } });
  console.log({ loading, data });

  return (
    <Card>
      <div>dsds</div>
    </Card>
  );
};

export default Timesheets;
