/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const UPDATE_DELIVERABLE = gql`
  mutation UpdateDeliverable(
    $id: ID!
    $deliverableName: String
    $milestoneName: String
    $accountingCode: String
    $accountingCodeSuffix: String
  ) {
    updateDeliverable(
      id: $id
      input: { deliverableName: $deliverableName, milestoneName: $milestoneName, accountingCode: $accountingCode, accountingCodeSuffix: $accountingCodeSuffix }
    )
  }
`;

