/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import Form from '../../common/components/Form';
import Tag from '../../common/components/Tag';
import Button from '../../common/components/Button';
import Icon from '../../common/components/Icon';
import Card from '../../common/components/Card';
import Table from '../../common/components/Table';
import TextLoader from '../../common/components/TextLoader';
import Input from '../../common/components/Input';
import Modal from '../../common/components/Modal';
import { ADD_USER_DELEGATED_APPROVER } from '../mutations';
import { GET_USER_DELEGATED_APPROVERS } from '../queries';

const FormItem = Form.Item;
const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class DelegatedApprovers extends React.Component {
  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.client
          .mutate({
            mutation: ADD_USER_DELEGATED_APPROVER,
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_USER_DELEGATED_APPROVERS,
              },
            ],
            variables: {
              type: values.type,
              value: values.value,
            },
          })
          .then(() => this.handleCancel());
      }
    });
  };

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  render() {
    const { delegatedApprovers, loading } = this.props;
    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
      },
    ];
    const {
      getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
    } = this.props.form;

    // Only show error after a field is touched.
    const typeError = isFieldTouched('type') && getFieldError('type');
    const valueError = isFieldTouched('value') && getFieldError('value');
    return (
      <Table
        columns={columns}
        footer={data => (
          <ActionWrapper>
            <Modal visible={this.state.visible} onCancel={this.handleCancel} footer={null}>
              <Form layout="inline" onSubmit={this.handleSubmit}>
                <FormItem validateStatus={typeError ? 'error' : ''} help={typeError || ''}>
                  {getFieldDecorator('type', {
                    rules: [
                      {
                        required: true,
                        message: 'Provide a type',
                      },
                    ],
                  })(<Input placeholder="type" />)}
                </FormItem>
                <FormItem validateStatus={valueError ? 'error' : ''} help={valueError || ''}>
                  {getFieldDecorator('value', {
                    rules: [
                      {
                        required: true,
                        message: 'Provide a value',
                      },
                    ],
                  })(<Input placeholder="value" />)}
                </FormItem>
                <FormItem>
                  <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                    Add
                  </Button>
                </FormItem>
              </Form>
            </Modal>
            <Button onClick={this.showModal} icon="plus">
              Add Approver
            </Button>
          </ActionWrapper>
        )}
        pagination={false}
        dataSource={delegatedApprovers && delegatedApprovers.map(a => ({ ...a, key: a.value }))}
      />
    );
  }
}

export default withApollo(Form.create()(DelegatedApprovers));
