import React, { useState, useEffect } from 'react';
import { getGlobal } from 'reactn';
import moment from 'moment';
import { Upload, message, Icon, DatePicker, Button, Spin } from 'antd';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER_TEST_PROOFS } from '../queries';
import client from '../../../api/graphql/client';
import { UPLOAD_TEST_PROOF, DELETE_TEST_PROOF } from '../mutations';
import { showError } from '../../common/notifications';
import Form from '../../common/components/Form';
import { uploadFileToAzureBlob } from '../../common';
import Card from '../../common/components/Card';

const TestProofListContainer = ({ user, startDate, endDate, submitted }) => {
  const startMoment = moment(startDate, 'YYYYMMDD');
  const endMoment = moment(endDate, 'YYYYMMDD');
  const numOfMonths = endMoment.diff(startMoment, 'months') + 1;
  const [months, setMonths] = useState(null);
  const [testDate, settestDate] = useState(moment());
  const [lastTestProofDate, setLastTestProofDate] = useState('');
  const [uploading, setUploading] = useState(false);
  let addNewLabelStyle = { color: 'red', fontSize: '12px', fontWeight: 'bold' };
  const disabledDate = current => {
    // Can not select days before today and today
    return current > moment().endOf('day');
  };
  const dateFormat = 'MM/DD/YYYY';
  const counterDivStyle = { padding: '5px', fontSize: '12px' };
  const beforeUpload = file => {
    const isJpg = file.type === 'image/jpeg';
    const isPng = file.type === 'image/png';
    if (!isJpg && !isPng) {
      message.error('You can only upload JPG/PNG files!');
    }
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error('Image must smaller than 20MB!');
    }
    return (isJpg || isPng) && isLt20M;
  };
  const uploadButton = uploading ? (
    <Spin />
  ) : (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const { loading, data, refetch } = useQuery(GET_USER_TEST_PROOFS);
  useEffect(() => {
    const testProof = get(data, 'userVaccineProofs.testDetails.testProof');
    if (data) {
      const _months = [...new Array(numOfMonths)].map((_, index) => {
        const name = new moment(endMoment).subtract(index, 'month').format('MMMM');
        const number = new moment(endMoment).subtract(index, 'month').format('MM');
        const year = new moment(endMoment).subtract(index, 'month').format('YYYY');

        const days = [];
        let monday = moment()
          .year(year)
          .month(Number(number) - 1)
          .date(1);
        do {
          if (monday.format('YYYYMM') === `${year}${number}`) {
            const endDate = monday.format('YYYYMMDD');
            days.push({
              endDate,
              testDetail:
                testProof &&
                testProof.find(t => {
                  if (t.date === endDate) {
                    setLastTestProofDate(endDate);
                    return t;
                  }
                }),
            });
          }
          monday.add(1, 'days');
        } while (monday.format('YYYYMM') === `${year}${number}`);

        return {
          name,
          number,
          year,
          days: days.sort((a, b) => b.endDate - a.endDate),
        };
      });
      setMonths(_months);
    }
  }, [loading, startDate, endDate]);

  const uploadHandler = async ({ file }) => {
    setUploading(true);

    const { user } = getGlobal();
    const { type } = file;
    const fileName = user._id + '_' + Date.now() + '_COVID_Test.' + type.split('/')[1];

    setUploading(true);
    const documentUrl = await uploadFileToAzureBlob({
      fileName,
      file,
      blobContainerName: 'uploads',
    });

    const date = moment(testDate).format('YYYYMMDD');
    client
      .mutate({
        mutation: UPLOAD_TEST_PROOF,
        awaitRefetchQueries: true,
        variables: {
          documentUrl,
          date,
        },
      })
      .then(res => {
        setUploading(false);
        refetch();
      })
      .catch(err => showError({ message: err.message }));
  };

  const deleteProof = date => {
    client
      .mutate({
        mutation: DELETE_TEST_PROOF,
        awaitRefetchQueries: true,
        variables: {
          date: date,
        },
      })
      .then(res => {
        refetch();
      })
      .catch(err => showError({ message: err.message }));
  };
  const TimeCounter = () => {
    var month = check => parseInt(check.format('M'));
    var day = check => parseInt(check.format('D'));
    var year = check => parseInt(check.format('YYYY'));
    const today = [year(moment()), month(moment()), day(moment())];
    const lastProof = [
      year(moment(lastTestProofDate)),
      month(moment(lastTestProofDate)),
      day(moment(lastTestProofDate)),
    ];
    const daysLeftToNextUpload = 7 - moment(today).diff(moment(lastProof), 'days');
    let counter;
    if (daysLeftToNextUpload > 0) {
      addNewLabelStyle.color = '#0ab30f';
      return (
        <div style={{ ...counterDivStyle, color: '#0ab30f' }}>
          <span
            style={{ fontWeight: 'bold' }}
          >{`${daysLeftToNextUpload} days until next test must be taken`}</span>
          <br />
          <span>{`Please Upload it as soon as you get the results`}</span>
        </div>
      );
    } else if (daysLeftToNextUpload == 0) {
      addNewLabelStyle.color = 'red';
      return (
        <div style={{ ...counterDivStyle, color: 'red' }}>
          <span style={{ fontWeight: 'bold' }}>{`Next Test should have been taken today`}</span>
          <br />
          <span>{`Please Upload it as soon as you get the results`}</span>
        </div>
      );
    } else if (daysLeftToNextUpload < 0) {
      addNewLabelStyle.color = 'red';
      return (
        <div style={{ ...counterDivStyle, color: 'red' }}>
          <span style={{ fontWeight: 'bold' }}>{`Next Test should have been taken ${Math.abs(
            daysLeftToNextUpload,
          )} days ago`}</span>
          <br />
          <span>{`Please Upload it as soon as you get the results`}</span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div>
      {months &&
        months.map(({ year, name, days }, index) => {
          return (
            <div key={index}>
              <div className="text-2xl font-medium mb-2">{`${name} ${year}`}</div>
              {index == 0 && (
                <div className="flex flex-wrap mb-4">
                  <div className="p-1 w-1/3">
                    <div className="p-2 rounded-md border border-gray-400 bg-inset border-solid h-32 shadow-inner">
                      <div className="flex justify-between">
                        <div className="text-lg text-gray-800">
                          <Form.Item
                            label={
                              lastTestProofDate ? (
                                <label style={addNewLabelStyle}>
                                  Date of next test must be{' '}
                                  {moment(lastTestProofDate).add(7, 'days').format('L')} or sooner
                                </label>
                              ) : (
                                `Date test was taken:`
                              )
                            }
                            className="p-4"
                          >
                            <DatePicker
                              name="lastVaccinationDate"
                              format={dateFormat}
                              disabledDate={disabledDate}
                              defaultValue={testDate}
                              onChange={date => settestDate(date)}
                            />
                          </Form.Item>
                        </div>
                        <div className="text-lg text-gray-800">
                          <Upload
                            name="proof"
                            listType="picture-card"
                            customRequest={value => uploadHandler(value)}
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            multiple={false}
                            disabled={uploading ? true : false}
                          >
                            {uploadButton}
                          </Upload>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-1 w-1/3">
                    <div>{TimeCounter()}</div>
                  </div>
                </div>
              )}

              {days.map(day => {
                if (day.testDetail) {
                  const { testDetail } = day;
                  const { proofUrl, updatedAt } = testDetail;

                  return (
                    <div key={day.endDate} className="flex flex-wrap mb-4">
                      <div className="p-1 w-1/3">
                        <div className="bg-white p-4 rounded-md hover:shadow-lg shadow-md transition-shadow duration-200">
                          <Card>
                            <p>
                              Date test was taken:
                              <span className="font-bold">{` ${moment(day.endDate).format('MM/DD/YYYY')}`}</span>
                            </p>
                            <img src={proofUrl} alt="Proof" style={{ width: '50%' }} />
                          </Card>
                        </div>
                      </div>
                      <div className="p-1 w-1/3">
                        <div
                          style={{
                            width: '40%',
                            padding: '5px',
                            fontSize: '12px',
                            color: '#0ab30f',
                          }}
                        >
                          {`Uploaded on:
                          ${moment(updatedAt).format('L LTS')}`}
                        </div>
                        <div style={{ marginTop: '2%', padding: '5px' }}>
                          <div>
                            {proofUrl && (
                              <a
                                target="_blank"
                                href={proofUrl}
                                style={{ textDecoration: 'underline', fontSize: '12px' }}
                              >
                                Download Test Card
                              </a>
                            )}
                          </div>
                          <div>
                            <Button
                              type="link"
                              size="small"
                              onClick={() => deleteProof(day.endDate)}
                              style={{ color: 'red', padding: '0' }}
                            >
                              <span style={{ textDecoration: 'underline' }}>Delete</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
    </div>
  );
};

export default TestProofListContainer;
