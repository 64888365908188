import React from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import Button from '../../common/components/Button';
import Form from '../../common/components/Form';
import Input from '../../common/components/Input';
import { CREATE_USER } from '../mutations';
import { ADMIN_GET_USERS } from '../queries';
import { Checkbox } from 'antd';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const NewUserForm = ({ onComplete, client, notifyUser }) => {
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        notify: notifyUser || false,
        payrollId: '',
      }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }

        if (values.password && !values.firstName) {
          errors.firstName = 'Required';
        }

        if (values.password && !values.lastName) {
          errors.lastName = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        client
          .mutate({
            mutation: CREATE_USER,
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: ADMIN_GET_USERS,
              },
            ],
            variables: {
              email: values.email,
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
              notify: values.notify,
              payrollId: values.payrollId,
            },
          })
          .then(res => {
            if (res.errors.length > 0 && res.errors[0].message === 'Error: User exists') {
              setErrors({ email: 'Email/User already in the system' });
            } else {
              onComplete();
            }
            setSubmitting(false);
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} {...formItemLayout}>
          <Form.Item
            label="E-mail"
            hasFeedback
            validateStatus={errors.email && touched.email && 'error'}
            help={errors.email && touched.email && errors.email}
            required
          >
            <Input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            hasFeedback
            validateStatus={errors.password && touched.password && 'error'}
            help={values.email.includes('@sprucetech.com') ? 'Password not required for Spruce emails.' : null}
            required={!values.email.includes('@sprucetech.com')}
          >
            <Input
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              disabled={isSubmitting || values.email.includes('@sprucetech.com')}
            />
          </Form.Item>
          {/* {values.password && values.password.length > 0 && ( */}
            <Form.Item
              label="First Name"
              hasFeedback
              validateStatus={errors.firstName && touched.firstName && 'error'}
              help={errors.firstName && touched.firstName && errors.firstName}
              required={!values.email.includes('@sprucetech.com')}
            >
              <Input
                name="firstName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                disabled={isSubmitting}
              />
            </Form.Item>
          {/* )} */}
          {/* {values.password && values.password.length > 0 && ( */}
            <Form.Item
              label="Last Name"
              hasFeedback
              validateStatus={errors.lastName && touched.lastName && 'error'}
              help={errors.lastName && touched.lastName && errors.lastName}
              required={!values.email.includes('@sprucetech.com')}
            >
              <Input
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                disabled={isSubmitting}
              />
            </Form.Item>
          {/* )} */}

          {/* {values.password && values.password.length > 0 && ( */}
            <Form.Item label="Payroll ID" hasFeedback>
              <Input
                name="payrollId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.payrollId}
                disabled={isSubmitting}
              />
            </Form.Item>
          {/* )} */}

          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            <Checkbox name="notify" checked={values.notify} onChange={handleChange}>
              Notify User Via Email
            </Checkbox>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              Create User
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

export default withApollo(NewUserForm);
