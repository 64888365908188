import React from 'react';
import moment from 'moment';
import Tag from '../../common/components/Tag';
import Table from '../../common/components/Table';
import Tooltip from '../../common/components/Tooltip';
import TextLoader from '../../common/components/TextLoader';
import TimesheetActionsContainer from './TimesheetActionsContainer';
import Profile from '../../common/components/Profile';
import { getColorByStatus } from '../../common';

class TimesheetApprovalList extends React.Component {
  render() {
    const { timesheets, loading, user } = this.props;

    const columns = [
      {
        title: 'Resource',
        key: 'resourceName',
        width: '30%',
        render: ({ user, resource }) => {
          const { firstName, lastName, imageUrl, title } = user;
          const role = resource?.role;
          const email = resource?.email;
          return (
            <Profile
              imageUrl={imageUrl}
              title={role || title}
              firstName={firstName}
              lastName={lastName}
              email={email}
            />
          );
        },
      },
      {
        title: 'Week',
        dataIndex: 'endDate',
        key: 'weekOf',
        render: endDate => moment(endDate).format('L'),
      },
      {
        title: 'Hours',
        dataIndex: 'totalHours',
        key: 'totalHours',
        render: totalHours => {
          const style = {
            color: 'black',
            fontWeight: 'normal',
            display: 'flex',
          };
          if (totalHours >= 50) {
            style.color = 'red';
            style.fontWeight = 'bold';
          }
          return (
            <div style={style}>
              {totalHours}
              {totalHours >= 50 ? (
                <Tooltip placement="top" title={'This timesheet has over 50 hours'}>
                  <img
                    style={{ height: '20px', width: '20px', paddingLeft: '5px', paddingTop: '5px' }}
                    src="/disclaimer-icon.jpg"
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        title: 'Approvals',
        dataIndex: 'approvalsRequired',
        key: 'approvalsRequired',
        render: approvalsRequired => {
          const approvelsGiven = approvalsRequired.reduce(
            (acc, cur) => (cur.status === 'Approved' ? acc + 1 : acc),
            0,
          );
          const numberOfApprovalsRequired = approvalsRequired.length;
          const tooltipText = approvalsRequired.reduce((acc, cur, index) => {
            if (index === 0) {
              return acc + cur.canApprove[0];
            }
            return `${acc} , ${cur.canApprove[0]}`;
          }, `Requires approval${approvalsRequired.length > 1 ? 's' : ''} from `);

          return (
            <Tooltip title={tooltipText} trigger="hover">
              <span>{`${approvelsGiven}/${numberOfApprovalsRequired}`}</span>
            </Tooltip>
          );
        },
      },
      {
        title: 'Overall Status',
        dataIndex: 'status',
        key: 'status',
        render: status => (
          <Tooltip title="Overall timesheet status" trigger="hover">
            <Tag color={getColorByStatus(status)}>{status}</Tag>
          </Tooltip>
        ),
      },
      {
        title: 'My Approval',
        dataIndex: 'approvalsRequired',
        key: 'myStatus',
        render: approvalsRequired => {
          // make sure user is present in props
          if (!user) {
            return null;
          }
          const userEmail = user.emails[0].address;
          const myApproval = approvalsRequired.find(approval =>
            approval.canApprove.includes(userEmail),
          );
          const status = (myApproval && myApproval.status) || 'Approval Pending';

          return (
            <Tooltip title="Status of your approval" trigger="hover">
              <Tag color={getColorByStatus(status)}>{status}</Tag>
            </Tooltip>
          );
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        width: 200,
        render: timesheet => <TimesheetActionsContainer timesheet={timesheet} />,
      },
    ];
    if (loading) {
      return <TextLoader text="Loading timesheets" />;
    }
    return (
      <Table
        columns={columns}
        pagination={false}
        locale={{
          emptyText: 'You currently do not have any timesheets to approve.',
        }}
        dataSource={
          // make sure timesheets exist and arrived to browser
          timesheets &&
          timesheets
            // add key property to timesheet objects. Its used by <Table />
            .map(t => ({ ...t, key: t._id }))
            // sort by endDate
            .sort((a, b) => {
              const diff = moment(b.endDate).diff(moment(a.endDate), 'days');
              return diff;
            })
        }
      />
    );
  }
}

export default TimesheetApprovalList;
