import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Link } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import Modal from '../../common/components/Modal';
import NewUserForm from './NewUserForm';
import { Checkbox } from 'antd';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

const Wrapper = styled.div`
  height: 600px;
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

class UserList extends React.Component {
  gridApi = undefined;
  state = {
    isModalVisible: false,
    showActive : true
  };

  onGridReady = params => {
    this.gridApi = params.api;
  };

  export = () => {
    const params = {
      fileName: `Spruce-Users-ExportedOn(${moment(new Date()).format('YYYYMMDD-hhmm')}).csv`,
    };
    this.gridApi.exportDataAsCsv(params);
  };
  render() {
    const { data, loading } = this.props;

    const filterData = this.state.showActive ? data?.filter(item=>item.accountStatus) : data
    const visibleRows = filterData?.map(item=>{
      return {
        ...item,
        accountStatus: item.accountStatus ? "Active" : "Inactive"
      }
    })

    const { isModalVisible } = this.state;
 
    return (
      <Card
        floating
        padded={false}
        title="Users"
        loading={loading}
        actionComponent={
          <div>
            <Checkbox onChange={e=>{this.setState({showActive:e.target.checked})}} checked={this.state.showActive}>Show Active / All</Checkbox>
            <Button onClick={() => this.setState({ isModalVisible: true })}>Add User</Button>
            <Button onClick={() => this.export()}>Export</Button>
            <Modal
              visible={isModalVisible}
              footer={null}
              onCancel={() => this.setState({ isModalVisible: false })}
            >
              <Box pt={4}>
                <NewUserForm
                  onComplete={() => this.setState({ isModalVisible: false })}
                  notifyUser={true}
                />
              </Box>
            </Modal>
          </div>
        }
      >
        <Wrapper className="ag-theme-balham">
          <AgGridReact
            modules={AllCommunityModules}
            rowData={visibleRows}
            onGridReady={this.onGridReady}
            enableCellTextSelection
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: true,
            }}
          >
            <AgGridColumn field="firstName" />
            <AgGridColumn field="lastName" sort='asc'/>
            <AgGridColumn field="payrollId" />
            <AgGridColumn field="accountStatus" headerName="Account Status" />
            <AgGridColumn
              width={140}
              field="lastActiveOn"
              headerName="Last Active On"
              valueFormatter={({ colDef, data }) => {
                const lastActiveOn = data[colDef.field];
                if (lastActiveOn) {
                  return moment(lastActiveOn).fromNow();
                }
              }}
            />
            <AgGridColumn
              width={140}
              field="approvers"
              headerName="Approvers"
              valueGetter={({ colDef, data }) => {
                const approvers = data[colDef.field];
                if (approvers.length > 0) {
                  if (approvers[0].type === 'self') {
                    return 'self';
                  }
                  return approvers.map(v => v.value).join(',');
                }
                return null;
              }}
            />
            <AgGridColumn
              width={140}
              field="delegatedApprovers"
              headerName="Delegated Approvers"
              valueGetter={({ colDef, data }) => {
                const value = data[colDef.field];
                return value.length > 0 ? value.map(v => v.value).join(',') : null;
              }}
            />
            <AgGridColumn field="emails.0.address" headerName="Email" />
            <AgGridColumn
              cellRendererFramework={({ data }) => (
                <Link to={`/admin/users/${data._id}`}>Details</Link>
              )}
            />
          </AgGridReact>
        </Wrapper>
      </Card>
    );
  }
}

export default UserList;
