import React, { useState } from 'react';
import Button from '../Button';
import Form from '../Form';
import { UPDATE_PROJECT, CREATE_ENDCLIENT } from './mutations';
import { Select, Checkbox, Input, Alert } from 'antd';
import client from '../../../../api/graphql/client';
import { TextArea } from '../Input';
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const UpdateProjectForm = ({
  projectId,
  onComplete,
  data,
  currentProject,
  refetchQueries = [],
}) => {
  const { endClient, name, description } = currentProject;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedClient, setSelectedClient] = useState(endClient ? [endClient] : '');
  const [createNew, setCreateNew] = useState(false);
  const [newName, setNewName] = useState('');
  const [newShortName, setNewShortName] = useState('');
  const [showError, setShowError] = useState(null);
  const [projectName, setProjectName] = useState(name);
  const [projectDescription, setProjectDescription] = useState(description);

  const changeHandler = e => {
    const currentClientObj = data ? data.filter(item => item._id == e) : [];
    setSelectedClient(currentClientObj);
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    setShowError(null);
    if (createNew) {
      if (newName && newShortName) {
        client
          .mutate({
            mutation: CREATE_ENDCLIENT,
            variables: {
              projectId: projectId,
              clientName: newName,
              clientShortName: newShortName,
              name: projectName,
              description: projectDescription,
            },
            awaitRefetchQueries: true,
            refetchQueries,
          })
          .then(() => {
            setIsSubmitting(false);
            onComplete();
          });
      } else {
        setShowError('Please fill all mandatory fields');
        setIsSubmitting(false);
      }
    } else if (selectedClient) {
      client
        .mutate({
          mutation: UPDATE_PROJECT,
          variables: {
            projectId: projectId,
            clientId: selectedClient[0]._id,
            clientName: selectedClient[0].name,
            clientShortName: selectedClient[0].shortName,
            name: projectName,
            description: projectDescription,
          },
          awaitRefetchQueries: true,
          refetchQueries,
        })
        .then(() => {
          setIsSubmitting(false);
          onComplete();
        })
        .catch(err => console.log(err));
    } else {
      setShowError('Please select an end client or create new ');
      setIsSubmitting(false);
    }
  };
  return (
    <Form {...formItemLayout}>
      <h3 style={{borderBottom: "1px solid #ebe9e9",paddingBottom: "5px"}}>Project Details :</h3>
      <Form.Item label="Project Name">
        <Input
          placeholder=""
          onChange={e => {
            setProjectName(e.target.value);
          }}
          value={projectName}
        />
      </Form.Item>
      <Form.Item label="Project Description">
        <TextArea
          placeholder=""
          onChange={e => {
            setProjectDescription(e.target.value);
          }}
          value={projectDescription}
        />
      </Form.Item>
      <h3  style={{borderBottom: "1px solid #ebe9e9",paddingBottom: "5px"}}>Billing Details :</h3>
      <Form.Item label="End Client Name" hasFeedback required={!createNew}>
        <Select
          name="name"
          showSearch
          placeholder="Select a Client"
          optionFilterProp="children"
          onChange={e => changeHandler(e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={selectedClient && selectedClient.length ? selectedClient[0]._id : ''}
        >
          {data &&
            data.map(item => {
              return (
                <Option value={item._id} key={item._id}>
                  {item.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item label="Create New End Client">
        <Checkbox onChange={e => setCreateNew(e.target.checked)}></Checkbox>
      </Form.Item>
      {createNew && (
        <div>
          <Form.Item label="Name of end client" required={createNew}>
            <Input
              placeholder=""
              onChange={e => {
                setNewName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Short name" required={createNew}>
            <Input
              placeholder=""
              onChange={e => {
                setNewShortName(e.target.value);
              }}
            />
          </Form.Item>
        </div>
      )}

      {showError && (
        <div style={{ paddingBottom: '10px' }}>
          <Alert message={showError} type="error" />
        </div>
      )}
      <div className="flex justify-end">
        <Button loading={isSubmitting} type="primary" onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </Form>
  );
};

export default UpdateProjectForm;
