import React, { useEffect } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import { Redirect } from 'react-router-dom';
import TextLoader from '../../common/components/TextLoader';
import client from '../../../api/graphql/client';
import { AuthenticationState } from 'react-aad-msal';
import { authProvider } from '../../../api/oauth/azure';
import history from '../../../api/redux/history';
import { datadogRum } from '@datadog/browser-rum';

const SignOut = () => {
  const [jwt] = useGlobal('jwt');
  useEffect(() => {
    localStorage.clear();
    client.resetStore();

    setTimeout(() => {
      setGlobal({ user: null, jwt: null });
      datadogRum.removeUser();
      if (authProvider.authenticationState === AuthenticationState.Authenticated) {
        authProvider.logout();
      }
    }, 1000);
  });

  if (jwt) {
    return <TextLoader text="Logging you out" />;
  }

  return <Redirect to="/auth/signin" />;
};

export default SignOut;
