import { Query } from '@apollo/react-components';
import React from 'react';
import TextLoader from '../../common/components/TextLoader';
import {
  EMAIL_STATS_RECEIVERS,
  GET_TIMESHEET_THRESHOLD,
  GET_WEEKLY_REMINDER_EMAIL,
} from '../queries';
import CronConfigContainer from './CronConfigContainer';
import EmailStatsRecieverContainer from './EmailStatsRecieverContainer';
import TimesheetThresholdRuleContainer from './TimesheetThresholdRuleContainer';
import { get } from 'lodash';

const Settings = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 p-2">
        <Query query={GET_TIMESHEET_THRESHOLD}>
          {({ loading, error, data }) => {
            if (error) throw new Error(error);
            if (loading) {
              return <TextLoader text="Loading" />;
            }
            const thresholdDetails = get(data, 'timesheetThreshold');
            return <TimesheetThresholdRuleContainer thresholdDetails={thresholdDetails} />;
          }}
        </Query>
      </div>
      <div className="w-full md:w-1/2 p-2">
        <Query query={EMAIL_STATS_RECEIVERS}>
          {({ loading, error, data }) => {
            if (error) throw new Error(error);
            if (loading) {
              return <TextLoader text="Loading" />;
            }
            const emailStatsRecievers = get(data, 'admin.emailStatsRecievers');
            const users = get(data, 'admin.users');
            return (
              <EmailStatsRecieverContainer
                users={users}
                emailStatsRecievers={emailStatsRecievers}
              />
            );
          }}
        </Query>
      </div>
      <div >
        <Query query={GET_WEEKLY_REMINDER_EMAIL}>
          {({ loading, error, data }) => {
            if (error) throw new Error(error);
            if (loading) {
              return <TextLoader text="Loading" />;
            }
            const weeklyReminderEmailJob = get(data, 'weeklyReminderEmailJob');
            return <CronConfigContainer weeklyReminderEmailJob={weeklyReminderEmailJob} />;
          }}
        </Query>
      </div>
    </div>
  );
};

export default Settings;
