import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Statistic, Empty, Button } from 'antd';
import Tag from '../../common/components/Tag';
import TimesheetListActions from './TimesheetListActions';
import { getColorByStatus } from '../../common';
import { CANCEL_TIMESHEET_TIMESHEET_LINEITEM } from '../../project-manager/mutations';
import { GET_TIMESHEETS_FOR_USER } from '../queries';

const mapStateToProps = state => ({
  user: state.meteor.user,
});

const TimesheetListContainer = ({ user, startDate, endDate }) => {
  const startMoment = moment(startDate, 'YYYYMMDD');
  const endMoment = moment(endDate, 'YYYYMMDD');
  const numOfMonths = endMoment.diff(startMoment, 'months') + 1;
  const [months, setMonths] = useState(null);
  const history = useHistory();
  const { loading, data, refetch } = useQuery(GET_TIMESHEETS_FOR_USER, {
    variables: { startDate, endDate },
  });
  const [cancelTimesheet] = useMutation(CANCEL_TIMESHEET_TIMESHEET_LINEITEM);

  useEffect(() => {
    const timesheets = get(data, 'me.timesheets');
    if (timesheets) {
      const _months = [...new Array(numOfMonths)].map((_, index) => {
        const name = new moment(endMoment).subtract(index, 'month').format('MMMM');
        const number = new moment(endMoment).subtract(index, 'month').format('MM');
        const year = new moment(endMoment).subtract(index, 'month').format('YYYY');

        const weeks = [];
        let sunday = moment()
          .year(year)
          .month(Number(number) - 1)
          .date(1)
          .endOf('isoWeek');
        do {
          const endDate = sunday.format('YYYYMMDD');
          weeks.push({
            endDate,
            timesheet: timesheets.find(t => t.endDate === endDate),
          });
          sunday.add(7, 'days');
        } while (sunday.format('YYYYMM') === `${year}${number}`);

        return {
          name,
          number,
          year,
          weeks: weeks.sort((a, b) => b.endDate - a.endDate),
          timesheets: timesheets?.filter(t => t.endDate.startsWith(`${year}${number}`)),
        };
      });
      setMonths(_months);
    }
  }, [loading]);

  return (
    <div>
      {months &&
        months.map(({ year, timesheets: timesheetsInMonth, name, weeks }, index) => {
          return (
            <div key={index}>
              <div className="text-2xl font-medium mb-2">{`${name} ${year}`}</div>
              <div className="flex flex-wrap mb-4">
                {weeks.map(week => {
                  if (week.timesheet) {
                    const { timesheet } = week;
                    const { _id, endDate, status, totalHours, approvalsRequired } = timesheet;
                    const approvedByEmails = approvalsRequired?.filter(approval => approval.approvedBy).map(approval => approval.approvedBy);
                    return (
                      <div key={_id} className="p-1 w-1/3">
                        <div className="bg-white p-4 rounded-md h-64 hover:shadow-lg shadow-md transition-shadow duration-200">
                          <div className="flex justify-between">
                            <div>
                              <Tag color={getColorByStatus(status)}>{status}</Tag>
                            </div>
                            <TimesheetListActions
                              timesheet={timesheet}
                              cancel={() =>
                                cancelTimesheet({
                                  variables: { timesheetId: _id },
                                })
                              }
                              refetch={refetch}
                            />
                          </div>
                          <div className="flex justify-between">
                            <div className="text-lg text-gray-800">
                              {moment(endDate, 'YYYYMMDD').format('LL')}
                            </div>
                          </div>
                          <div className="flex justify-between py-2">
                            <Statistic title="Total Hours" value={totalHours} />
                            <Statistic
                              title="Approvals Needed"
                              value={
                                approvalsRequired?.length === 0 ? 'None' : approvedByEmails?.length
                              }
                              suffix={
                                approvalsRequired?.length === 0
                                  ? null
                                  : `/ ${approvalsRequired?.length ? approvalsRequired.length : 0}`
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div key={week.endDate} className="p-1 w-1/3">
                      <div className="p-4 rounded-md border border-gray-400 bg-inset border-solid h-64 shadow-inner">
                        <div className="text-lg text-gray-800">
                          {moment(week.endDate, 'YYYYMMDD').format('LL')}
                        </div>
                        <div className="h-full flex items-center flex-col">
                          <div className="text-base text-gray-700 mb-4 mt-12">
                            No timesheet for this week
                          </div>
                          <Button
                            type="primary"
                            onClick={() => history.push(`/user/timesheets/${week.endDate}`)}
                          >
                            Create
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default connect(mapStateToProps)(TimesheetListContainer);
