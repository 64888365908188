/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const UPDATE_RESOURCE = gql`
  mutation UpdateResource(
    $id: ID!
    $startDate: Date!
    $endDate: Date
    $role: String
    $name: String
    $email: String!
  ) {
    updateResource(
      id: $id
      input: { startDate: $startDate, endDate: $endDate, role: $role, name: $name, email: $email }
    )
  }
`;

export const ADD_RESOURCE = gql`
  mutation addResource(
    $projectId: ID!
    $startDate: Date!
    $endDate: Date
    $role: String
    $name: String
    $email: String!
  ) {
    addResource(
      projectId: $projectId
      input: { startDate: $startDate, endDate: $endDate, role: $role, name: $name, email: $email }
    )
  }
`;

export const REMOVE_RESOURCE = gql`
  mutation removeResource($id: ID!) {
    removeResource(id: $id)
  }
`;
