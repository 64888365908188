// eslint-disable-next-line
import { getGlobal, setGlobal } from 'reactn';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import history from '../redux/history';
import { createUploadLink } from 'apollo-upload-client';

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const { jwt } = getGlobal();
    return {
      headers: {
        authorization: jwt ? `Bearer ${jwt}` : null,
      },
    };
  });
  return forward(operation);
});

const httpLink = createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_BASE_URL });
// eslint-disable-next-line
const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.log({ graphQLErrors, networkError });
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          console.log({ err });
          console.log("Unauthorized");
          localStorage.removeItem('jwt');
          setGlobal({ jwt: null, user: null });
          history.push('/auth/signin');
          return forward(operation);
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // throw new Error(error);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

// const link = middlewareLink.concat(httpLink, errorLink);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache,
  defaultOptions,
  name: 'web',
});

export default client;
