import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../../common/components/Button';
import Form from '../../common/components/Form';
import Input from '../../common/components/Input';
import { fadeIn } from '../../../globalStyles';
import client from '../../../api/graphql/client';
import gql from 'graphql-tag';

const RESET_PASSWORD_REQUEST = gql`
  mutation($email: String!) {
    resetPasswordRequest(email: $email)
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  animation: ${fadeIn} 500ms ease-in-out;
`;

const ResetPasswordRequestForm = ({ email = '', onComplete }) => {
  const [submitMessage, setSubmitMessage] = useState(null);
  if (submitMessage) {
    return (
      <Wrapper>
        <div>{submitMessage}</div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Formik
        initialValues={{ email }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }

          if (values.email.includes('@sprucetech.com')) {
            errors.email =
              'Log into Office 365 to reset your Spruce password.';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const { email } = values;

          setTimeout(async () => {
            const res = await client.mutate({
              mutation: RESET_PASSWORD_REQUEST,
              variables: {
                email,
              },
            });

            setSubmitting(false);
            setSubmitMessage(
              `Your password reset request for ${email} has been submitted. Check your email for more details.`,
            );
          }, 2000);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} layout="vertical">
            <Form.Item
              hasFeedback
              validateStatus={errors.email && touched.email && 'error'}
              help={errors.email && touched.email && errors.email}
            >
              <Input
                type="email"
                name="email"
                placeholder="Email"
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                disabled={isSubmitting}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: '.5rem' }}>
              <Button block size="large" type="primary" htmlType="submit" loading={isSubmitting}>
                Send reset link
              </Button>
            </Form.Item>
            <Form.Item>
              <Link to="/auth/signin">Back to sign In</Link>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default ResetPasswordRequestForm;
