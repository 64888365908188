import React from 'react';
import gql from 'graphql-tag';
import Timesheet from './Timesheet';
import { GET_TIMESHEET_THRESHOLD } from '../../admin/queries';
import { Query } from 'react-apollo';


export default ({ timesheet, endDate }) => (
  <Query query={GET_TIMESHEET_THRESHOLD} >
    {({ loading, error, data }) => {
      if (error) throw new Error(error);
      return <Timesheet timesheet={timesheet} endDate={endDate} threshold={data} />;
    }}
  </Query>
);
