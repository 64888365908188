import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TimesheetListCard from './TimesheetListCard';
import ProjectListContainer from '../../project-manager/components/ProjectListContainer';
import ProjectDetailsContainer from '../../project-manager/components/ProjectDetailsContainer';
import TimesheetWeeklySnapshotCardContainer from './TimesheetWeeklySnapshotCardContainer';
import ManualTimesheetsPage from '../components/ManualTimesheetsPage';
import TimesheetDailySnapshotCardContainer from './TimesheetDailySnapshotCardContainer';
import FinanceReports from './FinanceReports';
import PayrollAllocationReport from './PayrollAllocationReport';
import { GET_PROJECTS } from '../queries';
import { GET_PROJECT_BY_ID } from '../queries';

const FinancePage = () => (
  <Switch>
    <Route exact path="/finance" render={() => <Redirect to="/finance/timesheets" />} />
    <Route exact path="/finance/timesheets" render={() => <TimesheetListCard />} />
    <Route
      exact
      path="/finance/reports"
      render={() => <FinanceReports />}
    />
    <Route
      exact
      path="/finance/reports/payroll-allocation"
      render={() => <PayrollAllocationReport />}
    />
    <Route
      exact
      path="/finance/reports/weekly-snapshot"
      render={() => <TimesheetWeeklySnapshotCardContainer />}
    />    
    <Route
      path="/finance/reports/weekly-snapshot/:endDate"
      render={() => <TimesheetWeeklySnapshotCardContainer />}
    />
    <Route
      exact
      path="/finance/reports/daily-snapshot"
      render={() => <TimesheetDailySnapshotCardContainer />}
    />
    <Route
      exact
      path="/finance/reports/daily-snapshot/:endDate"
      render={() => <TimesheetDailySnapshotCardContainer />}
    />
    <Route
      exact
      path="/finance/projects"
      render={() => <ProjectListContainer query={GET_PROJECTS} />}
    />
    <Route
      exact
      path="/finance/projects/:projectId"
      render={() => <ProjectDetailsContainer query={GET_PROJECT_BY_ID} />}
    />
    <Route
      path="/finance/projects/:projectId/:activeTab"
      render={() => <ProjectDetailsContainer query={GET_PROJECT_BY_ID} />}
    />
    <Route exact path="/finance/manualTimesheets" component={ManualTimesheetsPage} />
    <Route exact path="/finance/manualTimesheets/:endDate" component={ManualTimesheetsPage} />

  </Switch>
);

export default FinancePage;
