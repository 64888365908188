import gql from 'graphql-tag';

export const HR_ALL_USERS = gql`
  query hrAllUsers {
      activeStaff {
        _id
        firstName
        lastName
        employeeType
        emails {
          address
        }
        approvers {
          type
          value
        }
        projects {
          name
          projectManagers {
            name
          }
        }
        lastActiveOn
      }
  }
`;

export const HR_DELINQUENCY = gql`
  query hrDelinquency(
    $startDate: String
    $endDate: String) {
      usersWithTimesheet(startDate:$startDate) {
        _id
        firstName
        lastName
        employeeType
        emails {
          address
        }
        timesheets(startDate: $startDate, endDate: $endDate){
          _id
          status
          startDate
          endDate
          timeline{
            date
            type
          }
          updatedAt
          createdAt
          submittedDate
        }
        lastActiveOn
      }
  }
`;
