import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import ProjectList from './ProjectList';
import { GET_PROJECTS } from '../queries';

const ProjectListContainer = ({ history, match, query = GET_PROJECTS }) => (
  <Query query={query}>
    {({ loading, error, data }) => {
      if (error) throw new Error(error);
      const projectId = get(match, 'params.projectId');
      const { location } = history;
      const { pathname } = location;

      if (pathname.includes('finance')) {
        const allProjects = get(data, 'finance.allProjects');
        return (
          <ProjectList
            title="All Projects"
            projectId={projectId}
            history={history}
            projects={allProjects}
            loading={loading}
          />
        );
      }
      const myProjects = get(data, 'projectManagement.myProjects');
      const allProjects = get(data, 'projectManagement.allProjects');
      return (
        <>
          <ProjectList
            title="My Projects"
            projectId={projectId}
            history={history}
            projects={myProjects}
            loading={loading}
          />
          {allProjects && (
            <ProjectList
              title="All Projects"
              projectId={projectId}
              history={history}
              projects={allProjects}
              loading={loading}
            />
          )}
        </>
      );
    }}
  </Query>
);

export default withRouter(ProjectListContainer);
