import React from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import { UPDATE_USER } from '../../../admin/mutations';
import { ADMIN_GET_USER_BY_ID } from '../../../admin/queries';
import client from '../../../../api/graphql/client';
import { showSuccess } from '../../../common/notifications';
import Button from '../Button';
import Form from '../Form';
import Card from '../Card';
import Input from '../Input';

const UpdateUserForm = ({ data, title, userId }) => {
  return (
    <Card title={title} floating>
      <Formik
        initialValues={{
          title: data.title,
          firstName: data.firstName,
          lastName: data.lastName,
          payrollId: data.payrollId,
        }}
        validate={values => {
          const errors = {};
          if (values.password && !values.firstName) {
            errors.firstName = 'Required';
          }

          if (values.password && !values.lastName) {
            errors.lastName = 'Required';
          }
          return errors;
        }}
        className="flex flex-wrap"
        onSubmit={async (values, { setSubmitting }) =>
          client
            .mutate({
              mutation: UPDATE_USER,
              awaitRefetchQueries: true,
              variables: {
                title: values.title,
                firstName: values.firstName,
                lastName: values.lastName,
                payrollId: values.payrollId,
                userId,
              },
              refetchQueries: [
                {
                  query: ADMIN_GET_USER_BY_ID,
                  variables: {
                    userId,
                  },
                },
              ],
            })
            .then(() => {
              setSubmitting(false);
              showSuccess({ message: 'Updated Successfully' });
            })
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit} layout="vertical" className="flex flex-wrap">
              <Form.Item
                label="First Name"
                hasFeedback
                validateStatus={errors.firstName && touched.firstName && 'error'}
                help={errors.firstName && touched.firstName && errors.firstName}
                className="w-full md:w-1/2 p-2"
              >
                <Input
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  disabled={isSubmitting}
                />
              </Form.Item>
              <Form.Item
                label="Last Name"
                hasFeedback
                validateStatus={errors.lastName && touched.lastName && 'error'}
                help={errors.lastName && touched.lastName && errors.lastName}
                className="w-full md:w-1/2 p-2"
              >
                <Input
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  disabled={isSubmitting}
                />
              </Form.Item>
              <Form.Item label="Title" hasFeedback className="w-full md:w-1/2 p-2">
                <Input
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  disabled={isSubmitting}
                />
              </Form.Item>
              <Form.Item label="Payroll ID" hasFeedback className="w-full md:w-1/2 p-2">
                <Input
                  name="payrollId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.payrollId}
                  disabled={isSubmitting}
                />
              </Form.Item>
              <Form.Item className="w-full md:w-2/2 p-2">
                <Button loading={isSubmitting} type="primary" htmlType="submit">
                  Update User Info
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default withApollo(UpdateUserForm);
