import React, { useState } from 'react';
import { Upload, message, Icon, DatePicker, Button, Spin, Input } from 'antd';
import Form from '../../common/components/Form';
import client from '../../../api/graphql/client';
import { showSuccess, showError } from '../../common/notifications';
import { uploadFileToAzureBlob } from '../../common';
import { GET_USER_TIMESHEET } from '../queries';
import { UPLOAD_TIMESHEET_EXPENSE, DELETE_TIMESHEET_EXPENSE } from '../mutations';
import moment from 'moment';
import { useGlobal } from 'reactn';

const TimesheetExpense = ({ timesheet, endDate, readonly }) => {
  const { timesheetExpense, startDate } = timesheet;
  const [user] = useGlobal('user');
  const [uploading, setUploading] = useState(false);
  const [description, setDescription] = useState(
    timesheetExpense?.description
      ? timesheetExpense.description
      : `${user.firstName?.toLowerCase()}'s ${moment(startDate)
          .format('MMMM YYYY')
          .toLowerCase()} expenses`,
  );
  const [amount, setAmount] = useState(
    timesheetExpense?.amount ? timesheetExpense.amount : 0,
  );
  const beforeUpload = file => {
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error('Image must smaller than 20MB!');
    }
    return isLt20M;
  };
  const uploadButton = uploading ? (
    <Button>
      <Spin style={{ padding: '5px' }} />
      <span style={{ padding: '5px' }}>Uploading..</span>
    </Button>
  ) : (
    <Button>
      <Icon type="upload" /> Upload Attachments
    </Button>
  );
  const saveExpense = () => {
    client
      .mutate({
        mutation: UPLOAD_TIMESHEET_EXPENSE,
        variables: {
          timeSheetId: timesheet._id,
          description: description,
          amount: parseFloat(amount),
        },
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: { endDate },
          },
        ],
      })
      .then(() => {
        showSuccess({ message: 'Updated Successfully' });
        setUploading(false);
      })
      .catch(err => showError({ message: err.message }));
  };
  const uploadHandler = async ({ file }) => {
    setUploading(true);
    const { type, name } = file;
    const fileName = `${Date.now()}_${name}`;
    const url = await uploadFileToAzureBlob({
      fileName,
      file,
      blobContainerName: 'uploads',
    });
    client
      .mutate({
        mutation: UPLOAD_TIMESHEET_EXPENSE,
        variables: {
          timeSheetId: timesheet._id,
          url: url,
        },
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: { endDate },
          },
        ],
      })
      .then(() => {
        showSuccess({ message: 'Updated Successfully' });
        setUploading(false);
      })
      .catch(err => showError({ message: err.message }));
  };

  const deleteHandler = id => {
    client
      .mutate({
        mutation: DELETE_TIMESHEET_EXPENSE,
        variables: {
          timeSheetId: timesheet._id,
          id: id,
        },
        refetchQueries: [
          {
            query: GET_USER_TIMESHEET,
            variables: { endDate },
          },
        ],
      })
      .then(() => {
        showSuccess({ message: 'Deleted Successfully' });
        setUploading(false);
      })
      .catch(err => showError({ message: err.message }));
  };

  return (
    <div>
      {!readonly ? (
        <>
          <div style={{ margin: '10px', fontSize: '15px' }}>
            <div
              className="flex"
            >
              <Form layout="inline" className="flex">
                <Form.Item label="Brief Description" className="">
                  <Input
                    name="description"
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Amount" className="">
                  <Input
                    name="amount"
                    type="number"
                    value={amount}
                    style={{ width: '50%' }}
                    onChange={e => {
                      setAmount(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="" className="" onClick={saveExpense}>
                  <Button type="info">Save</Button>
                </Form.Item>
              </Form>
            </div>
            <ol style={{ paddingLeft: '10px' }}>
              <li>Please input a brief description of your expenses and the amount.</li>
              <li>Please upload your populated Spruce expenses XLS template if any expenses incurred.</li>
              <li>Please upload all your reciepts in a zipped file.</li>
            </ol>
          </div>
          <div style={{ margin: '10px', fontSize: '15px' }}>
            <div
              style={{
                margin: '10px',
                fontSize: '15px',
              }}
            >
              <Upload
                name="proof"
                customRequest={value => uploadHandler(value)}
                beforeUpload={beforeUpload}
                multiple={false}
                showUploadList={false}
                disabled={uploading ? true : false}
              >
                {uploadButton}
              </Upload>
            </div>
          </div>
        </>
      ) : (
        <div style={{ margin: '10px', fontSize: '15px' }}>
          <div
            style={{
              margin: '10px',
              fontSize: '15px',
              height: '40px',
            }}
            className="flex"
          >
            <span style={{ margin: '0px 20px' }}>
              <span style={{ fontWeight: 'bold' }}>Description : </span>
              {description}
            </span>
            <span style={{ margin: '0px 20px' }}>
              <span style={{ fontWeight: 'bold' }}>Amount : </span>
              {amount}
            </span>
          </div>
        </div>
      )}
      <div>
        {timesheetExpense &&
          timesheetExpense.attachments?.map(item => {
            return (
              <div style={{ margin: '10px', fontSize: '15px' }} key={item._id}>
                <div
                  className="flex"
                  style={{
                    margin: '10px',
                    fontSize: '15px',
                    borderRadius: '15px',
                  }}
                >
                  <div style={{ width: '100%', margin: '5px' }}>
                    <Icon
                      type="paper-clip"
                      style={{ marginTop: '3px', padding: '0px 5px 0px 5px' }}
                    />
                    <a href={item.url} target="_blank">
                      {item.url.replace(
                        'https://spruceportaldev.blob.core.windows.net/uploads/',
                        '',
                      )}
                    </a>
                    {timesheet.status == 'Not Submitted' && !readonly && (
                      <Button
                        type="link"
                        style={{ color: 'red' }}
                        onClick={() => {
                          deleteHandler(item._id);
                        }}
                      >
                        <Icon type="delete" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {timesheetExpense &&
        (!timesheetExpense.attachments || timesheetExpense.attachments.length === 0) &&
        readonly && (
          <div style={{ textAlign: 'center', margin: '10px', padding: '10px' }}>No Attachments</div>
        )}
    </div>
  );
};

export default TimesheetExpense;
