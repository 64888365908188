import React from 'react';
import { get } from 'lodash';
import Profile from '../../common/components/Profile';
import { Flex, Box } from 'rebass/styled-components';
import UserApprovers from '../../common/components/UserApprovers';
import ResetUserPassword from '../../common/components/ResetUserPassword';
import Timesheets from './Timesheets';
import UpdateUserForm from '../../common/components/UpdateUserForm';
import ActiveInactiveAccount from '../../common/components/ActiveInactiveAccount';
import UserGroups from './UserGroups';
import UserProjects from '../../common/components/UserProjects';

const UserDetails = ({ data, loading }) => {
  const { user } = data;
  const firstName = get(user, 'firstName');
  const lastName = get(user, 'lastName');
  const accountStatus = get(user,'accountStatus')
  const id = get(user, '_id');
  const email = get(user, 'emails[0].address');
  const payrollId = get(user, 'payrollId');
  const approvers = get(user, 'approvers');
  const delegatedApprovers = get(user, 'delegatedApprovers');
  const imageUrl = get(user, 'imageUrl');
  const title = get(user, 'title');
  const groups = get(user,'groups');
  const projects =  get(user,'projects')
  const userData = [
    {
      firstName,
      lastName,
      id,
      email,
      accountStatus
    },
  ];

  if (loading) {
    return 'loading';
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-full flex justify-start">
        <Profile
          imageUrl={imageUrl}
          title={title}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      </div>
      <div className="w-full p-2">
        <UpdateUserForm
          title="Edit Information"
          data={{ title, firstName, lastName, payrollId }}
          userId={id}
        />
      </div>
      <div className="w-full p-2">
        <UserGroups
          title="User Groups"
          groups={groups}
          userId={id}
          email= {email}
        />
      </div>
      <div className="w-full md:w-1/2 p-2">
        <UserApprovers title="Approvers" data={approvers} userId={id} userEmail={email} />
      </div>

      <div className="w-full md:w-1/2 p-2">
        <UserApprovers
          title="Delegated Approvers"
          data={delegatedApprovers}
          userId={id}
          userEmail={email}
          delegated
        />
      </div>
      

      <div className="w-full md:w-1/2 p-2">
        <ResetUserPassword title="Reset User Password" data={userData} />
      </div>
      <div className="w-full md:w-1/2 p-2">
        <ActiveInactiveAccount title="Active/Inactive Account" data={userData} />
      </div>
      <div className="w-full md:w-1/2 p-2">
        <UserProjects
          title="Projects"
          data={projects}
          
        />
      </div>

      {/* <div className="w-full p-2">
        <Timesheets userId={id}></Timesheets>
      </div> */}
    </div>
  );
};

export default UserDetails;
