import React from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import Input from '../../common/components/Input';
import { GET_USER_TIMESHEET } from '../queries';
import { UPDATE_HOURS_MUTATION, ADD_HOURS_MUTATION } from '../mutations';

const StyledInput = styled(Input)`
  background: ${props => (props.background ? props.background : 'white')};
  text-align: center;
  border-radius: 0;
  &[type='number'] {
    appearance: textfield;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const LockedHours = styled.span`
  font-size: 1.1em;
`;

class TimesheetGridRowHour extends React.Component {
  state = {
    hours: this.props.task ? this.props.task.hours : null,
    isUpdating: false,
  };

  handleSave = e => {
    const value = e.target.value === '' ? null : parseFloat(e.target.value);
    this.save(value);
  };

  setUpdating = (isUpdating, delay) => {
    if (delay) {
      setTimeout(() => {
        this.setState({ isUpdating });
      }, 300);
    } else {
      this.setState({ isUpdating });
    }
  };

  save = value => {
    const { client, task, endDate, user } = this.props;
    const hours = task ? task.hours : null;
    if (hours !== value) {
      this.setUpdating(true);
      if (task) {
        client
          .mutate({
            mutation: UPDATE_HOURS_MUTATION,
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_USER_TIMESHEET,
                variables: {
                  endDate,
                  user,
                },
              },
            ],
            variables: {
              taskId: task._id,
              hours: value,
            },
          })
          .then(() => this.setUpdating(false, 300));
      } else {
        const { newTaskRequiredFields } = this.props;
        client
          .mutate({
            mutation: ADD_HOURS_MUTATION,
            awaitRefetchQueries: true,
            variables: {
              ...newTaskRequiredFields,
              hours: value,
              user,
            },
            refetchQueries: [
              {
                query: GET_USER_TIMESHEET,
                variables: {
                  endDate,
                  user,
                },
              },
            ],
          })
          .then(() => this.setUpdating(false, 300));
      }
    }
  };

  handleChange = e => {
    const { value } = e.target;
    const hours = value === '' ? null : Number(value);
    if (value >= 0) {
      this.setState({ hours });
    }
  };

  render() {
    const { isLocked, disabled, backgroundColor } = this.props;
    const { isUpdating, hours } = this.state;
    if (isLocked) {
      return <LockedHours>{hours || undefined}</LockedHours>;
    }
    if (disabled) {
      return <StyledInput disabled value={hours || undefined} />;
    }
    return (
      <StyledInput
        disabled={isUpdating || isLocked}
        onChange={this.handleChange}
        onBlur={this.handleSave}
        onPressEnter={this.handleSave}
        background={backgroundColor || 'white'}
        value={hours}
        type="number"
        step="0.25"
      />
    );
  }
}

export default withApollo(TimesheetGridRowHour);
