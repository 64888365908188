/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import Card from '../../common/components/Card';
import { Flex, Box } from 'rebass/styled-components';
import ApproversContainer from './ApproversContainer';
import DelegatedApproversContainer from './DelegatedApproversContainer';
import PasswordChange from './PasswordChange';

const Wrapper = styled.div`
  display: flex;
  padding: 1em;
  > div {
    margin: 0 0.5em;
  }
  flex-flow: row nowrap;
  justify-content: stretch;
`;

class ProfilePage extends React.Component {
  render() {
    return (
      <Flex>
        <Box width={1}>
          <PasswordChange />
        </Box>
      </Flex>
    );
  }
}

export default ProfilePage;
