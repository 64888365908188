import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import Modal from '../../common/components/Modal';
import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';
import ResetUserPasswordForm from '../../common/components/ResetUserPassword/ResetUserPasswordForm';
import UpdateResourceForm from '../../common/components/UpdateResourceForm';
import { REMOVE_RESOURCE } from '../../common/components/UpdateResourceForm/mutations';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ProjectDetailsResourceActions = ({ user, resource, refetchQueries }) => {
  const [removeResource] = useMutation(REMOVE_RESOURCE);
  const { email, totalApprovedTimesheets } = resource;
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [updateResourceVisible, setUpdateResourceVisible] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const disabled = totalApprovedTimesheets > 0;
  return (
    <ActionsWrapper>
      <div className="flex">
        <Button onClick={() => setUpdateResourceVisible(true)}>Edit</Button>
        <Button className="mx-2" onClick={() => setResetPasswordVisible(true)}>
          Reset Password
        </Button>
        <Tooltip
          title={
            disabled ? 'Resource has approved timesheets and cannot be removed from project.' : null
          }
          trigger="hover"
        >
          <Button
            disabled={disabled}
            loading={isRemoving}
            type="danger"
            icon="close"
            onClick={async () => {
              setIsRemoving(true);
              await removeResource({
                variables: {
                  id: resource._id,
                },
                refetchQueries,
                awaitRefetchQueries: true,
              });
            }}
          />
        </Tooltip>
      </div>
      <Modal
        title="Reset Resource Password"
        visible={resetPasswordVisible}
        destroyOnClose
        onOk={() => setResetPasswordVisible(false)}
        onCancel={() => setResetPasswordVisible(false)}
        footer={null}
      >
        <ResetUserPasswordForm
          onComplete={() => setResetPasswordVisible(false)}
          userEmail={email}
        />
      </Modal>
      <Modal
        title="Update Resource"
        visible={updateResourceVisible}
        destroyOnClose
        onOk={() => setUpdateResourceVisible(false)}
        onCancel={() => setUpdateResourceVisible(false)}
        footer={null}
      >
        <UpdateResourceForm
          resource={resource}
          refetchQueries={refetchQueries}
          onComplete={() => setUpdateResourceVisible(false)}
        />
      </Modal>
    </ActionsWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.meteor?.user,
});

export default connect(mapStateToProps)(ProjectDetailsResourceActions);
