import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { setGlobal } from 'reactn';
import history from '../../../api/redux/history';
import Button from '../../common/components/Button';
import Form from '../../common/components/Form';
import Input from '../../common/components/Input';
import { fadeIn } from '../../../globalStyles';
import client from '../../../api/graphql/client';
import TextLoader from '../../common/components/TextLoader';

const RESET_PASSWORD_FROM_TOKEN = gql`
  mutation($password: String!, $token: String!) {
    resetPasswordFromToken(password: $password, token: $token)
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  animation: ${fadeIn} 500ms ease-in-out;
`;

const ResetPasswordForm = ({ token }) => {
  const [submitMessage, setSubmitMessage] = useState(null);
  if (submitMessage) {
    return (
      <Wrapper>
        <TextLoader text={submitMessage} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          newPassword1: '',
          newPassword2: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.newPassword1) {
            errors.newPassword1 = 'Required';
          }
          if (!values.newPassword2) {
            errors.newPassword2 = 'Required';
          }

          if (values.newPassword1 !== values.newPassword2) {
            errors.newPassword1 = 'Passwords do not match';
            errors.newPassword2 = 'Passwords do not match';
          }

          return errors;
        }}
        onSubmit={async ({ newPassword1 }, { setSubmitting, setErrors }) => {
          setTimeout(async () => {
            const res = await client.mutate({
              mutation: RESET_PASSWORD_FROM_TOKEN,
              variables: {
                password: newPassword1,
                token,
              },
            });

            setSubmitting(false);

            if (res.data.resetPasswordFromToken) {
              setSubmitMessage('Your password has been reset. Logging you in now.');

              setTimeout(() => {
                setGlobal({ jwt: res.data.resetPasswordFromToken });
                localStorage.setItem('jwt', res.data.resetPasswordFromToken);
                history.push(`/user/timesheets`);
              }, 2000);
            }
          }, 2000);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} layout="vertical">
            <Form.Item
              label="New Password"
              hasFeedback
              required
              validateStatus={errors.newPassword1 && touched.newPassword1 && 'error'}
              help={errors.newPassword1 && touched.newPassword1 && errors.newPassword1}
            >
              <Input
                type="password"
                name="newPassword1"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword1}
                disabled={isSubmitting}
              />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              hasFeedback
              required
              validateStatus={errors.newPassword2 && touched.newPassword2 && 'error'}
              help={errors.newPassword2 && touched.newPassword2 && errors.newPassword2}
            >
              <Input
                type="password"
                name="newPassword2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword2}
                disabled={isSubmitting}
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: '.5rem' }}>
              <Button block size="large" type="primary" htmlType="submit" loading={isSubmitting}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default ResetPasswordForm;
