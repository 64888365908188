import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import history from '../../../api/redux/history';
import { Link } from 'react-router-dom';
// import { Meteor } from 'meteor/meteor';
import Button from '../../common/components/Button';
import Form from '../../common/components/Form';
import Input from '../../common/components/Input';
import { fadeIn } from '../../../globalStyles';
import client from '../../../api/graphql/client';
import gql from 'graphql-tag';
import { setGlobal } from 'reactn';

const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  animation: ${fadeIn} 500ms ease-in-out;
`;

const ResetButton = styled(Button)`
  padding-left: 2px;
`;

const NewUserForm = ({ onComplete }) => (
  <Wrapper>
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }

        // if (values.email.includes('@sprucetech.com')) {
        //   errors.email = 'Use the link above to log in with your Spruce email.';
        // }

        if (!values.password) {
          errors.password = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const { email, password } = values;

        setTimeout(async () => {
          const res = await client.mutate({
            mutation: LOGIN,
            variables: {
              email: email.toLocaleLowerCase(),
              password,
            },
          });

          if (res.errors) {
            setErrors({
              email: 'The email provided may be incorrect.',
              password: 'The password provided may be incorrect.',
            });
            setSubmitting(false);
          } else {
            setGlobal({ jwt: res.data.login });
            localStorage.setItem('jwt', res.data.login);
            history.push(`/user/timesheets`);
          }
        }, 2000);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} layout="vertical">
          <Form.Item
            hasFeedback
            validateStatus={errors.email && touched.email && 'error'}
            help={errors.email && touched.email && errors.email}
          >
            <Input
              type="email"
              name="email"
              placeholder="Email"
              size="large"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            validateStatus={errors.password && touched.password && 'error'}
            help={errors.password && touched.password && errors.password}
          >
            <Input
              type="password"
              name="password"
              placeholder="Password"
              size="large"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              disabled={isSubmitting}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: '.5rem' }}>
            <Button block size="large" type="primary" htmlType="submit" loading={isSubmitting}>
              Log In
            </Button>
          </Form.Item>
          <Form.Item>
            <Link to="/auth/reset-password">Reset Password</Link>
          </Form.Item>
        </Form>
      )}
    </Formik>
  </Wrapper>
);

export default NewUserForm;
