import React, { useState } from 'react';
import styled from 'styled-components';
import TimesheetAttachments from './TimesheetAttachments';
import TimesheetExpense from './TimesheetExpense';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const TimesheetUploadsContainer = ({ timesheet, endDate, readonly }) => {
  const lineItems = timesheet && timesheet.lineItems;
  const [defaultTab,setDefaultTab] = useState("1")
  const Wrapper = styled.div`
    background-color: white;
    padding: 1em;
  `;
  const TimesheetHeader = styled.div`
    justify-content: space-between;
    > * {
      margin: 0 0.5em;
    }
    > *:first-child {
      margin-left: 0;
    }
    > *:last-child {
      margin-right: 0;
    }
  `;
  const HeadStyle = styled.div`
    font-size: 1.7em;
    font-weight: 300;
    width: 100%;
    margin-right: 1em;
  `;
  return (
    <>
      {((lineItems && lineItems.length > 0) || readonly) && (
        <Wrapper>
          <Tabs defaultActiveKey={defaultTab} onChange={(e)=>{setDefaultTab(e)}}>
            <TabPane tab="Attachments" key="1" >
              <div>
                <TimesheetHeader>
                  {/* <HeadStyle>Attachments</HeadStyle> */}
                  {!readonly &&
                    `You may upload supplementary documents, if available (i.e client timesheets etc.)`}
                </TimesheetHeader>
                <TimesheetAttachments timesheet={timesheet} endDate={endDate} readonly={readonly} />
              </div>
            </TabPane>
            <TabPane tab="Expenses" key="2" >
              <div>
                <TimesheetExpense timesheet={timesheet} endDate={endDate} readonly={readonly} />
              </div>
            </TabPane>
          </Tabs>
        </Wrapper>
      )}
    </>
  );
};

export default TimesheetUploadsContainer;
