import React from 'react';
import { withApollo } from 'react-apollo';
import { Formik } from 'formik';
import { ADD_APPROVER } from '../../../admin/mutations';
import { ADMIN_GET_USER_BY_ID } from '../../../admin/queries';
import { Select } from 'antd';
import Button from '../Button';
import Form from '../Form';
import Input from '../Input';

const { Option } = Select;

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const NewApproverForm = ({ onComplete, client, userEmail, userId, delegated = false }) => (
  <Formik
    initialValues={{
      type: null,
      value: null,
    }}
    validate={values => {
      const errors = {};
      if (!values.type) {
        errors.type = 'Select a type';
      }
      if (values.type === 'role' && !values.value) {
        errors.value = 'Select a role';
      }
      if (values.type === 'individual' && !values.value) {
        errors.value = 'Enter approvers email';
      }
      if (values.type === 'individual' && !validateEmail(values.value)) {
        errors.value = 'Please enter a valid email';
      }
      return errors;
    }}
    onSubmit={async (values, { setSubmitting }) =>
      client
        .mutate({
          mutation: ADD_APPROVER,
          awaitRefetchQueries: true,
          variables: {
            userEmail,
            type: values.type,
            value: values.value,
            delegated,
          },
          refetchQueries: [
            {
              query: ADMIN_GET_USER_BY_ID,
              variables: {
                userId,
              },
            },
          ],
        })
        .then(() => {
          setSubmitting(false);
          onComplete();
        })
    }
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      isValid,
      setFieldValue,
    }) => {
      return (
        <Form onSubmit={handleSubmit} layout="vertical">
          <Form.Item
            label="Type"
            hasFeedback
            validateStatus={errors.type && touched.type && 'error'}
            help={errors.type && touched.type && errors.type}
            required
          >
            <Select
              value={values.type}
              disabled={isSubmitting}
              onChange={value => {
                setFieldValue('type', value);
                setFieldValue('value', null);
              }}
              onBlur={handleBlur}
            >
              <Option value="individual">Individual</Option>
              <Option value="role">Role</Option>
              <Option value="self">Self</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={(() => {
              if (values.type === 'role') {
                return 'Select project role';
              }
              if (values.type === 'individual') {
                return 'Enter approver email';
              }
            })()}
            hasFeedback
            validateStatus={errors.value && touched.value && 'error'}
            help={errors.value && touched.value && errors.value}
            required={values.type === 'role' || values.type === 'individual'}
          >
            {values.type === 'role' && (
              <Select
                value={values.value}
                disabled={isSubmitting}
                defaultValue="Project Manager"
                onChange={value => setFieldValue('value', value)}
                onBlur={handleBlur}
              >
                <Option value="Project Manager">Project Manager</Option>
                <Option value="Engagement Manager">Engagement Manager</Option>
              </Select>
            )}
            {values.type === 'individual' && (
              <Input
                type="value"
                name="value"
                onChange={e => setFieldValue('value', e.target.value.replaceAll(' ', ''))}
                onBlur={handleBlur}
                value={values.value}
                disabled={isSubmitting || !values.type || values.type === 'self'}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button disabled={!isValid} loading={isSubmitting} type="primary" htmlType="submit">
              Create Approver
            </Button>
          </Form.Item>
        </Form>
      );
    }}
  </Formik>
);

export default withApollo(NewApproverForm);
