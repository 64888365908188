import styled from 'styled-components';
import Table from 'antd/lib/table';

const StyledTable = styled(Table)`
  .ant-table {
    font-size: 1em;
    background-color: white;
    border-radius: 5px;
  }
  .ant-table-thead {
    height: 40px;
  }
  .ant-table-pagination {
    padding: 0 1em;
  }
  .ant-table-thead th {
    text-transform: uppercase;
    width: 200px;
    span {
      font-weight: 700;
    }
    font-size: 0.8em;
  }
  .ant-table-row:last-child td,
  .ant-table-placeholder {
    border-bottom: none;
  }
  td:last-of-type > td {
    display: flex;
    justify-content: space-between;
    width: 150px;
    margin-left: auto;
  }
  .ant-table-thead th,
  .ant-table-tbody > tr > td {
    height: 25px;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .ant-table-tbody {
    .ant-table-row {
      &:hover {
        /* background: blue; */
      }
      td {
        background: none !important;
      }
    }
  }
  ${props =>
    !props.highlightOnHover &&
    `
     .ant-table-thead > tr.ant-table-row-hover > td,
     .ant-table-tbody > tr.ant-table-row-hover > td,
     .ant-table-thead > tr:hover > td,
     .ant-table-tbody > tr:hover > td {
       background: initial;
     }
  `};
  .ant-table-expanded-row,
  .ant-table-expanded-row:hover {
    background-color: white;
  }
`;

export default StyledTable;
