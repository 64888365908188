import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Empty } from 'antd';
import TimesheetGridRow from './TimesheetGridRow';
import TimesheetGridRowAddNew from './TimesheetGridRowAddNew';
import Tag from '../../common/components/Tag';
import Tooltip from '../../common/components/Tooltip';
import { GET_AVAILABLE_DELIVERABLES } from '../queries';
import { Query } from 'react-apollo';

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;
const Header = styled.thead``;
const HeaderRow = styled.tr``;
const HeaderCol = styled.th`
  padding: 1em;
  border-bottom: 1px solid #e9e9e9;
  text-align: ${props => (props.align ? props.align : 'initial')};
  width: ${props => props.width};
  &:first-child {
    padding-left: 0;
  }
`;

const Day = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
`;

const ProjectName = styled.tr`
  border-top: 1px solid #f1f1f1;
  &:first-child {
    border-top: none;
  }
  > td {
    padding: 1rem 0 0.5rem 0;
    font-weight: bold;
  }
`;

// use lowercase 3 char week day
const sumTotalsForWeekDay = (weekDay, lineItems) =>
  lineItems.reduce((acc, cur) => {
    const weekDayTask = cur[`${weekDay}Task`];
    if (cur.deliverable.ignoreHoursInTotal) {
      return acc;
    }

    if (weekDayTask) {
      return acc + weekDayTask.hours;
    }

    return acc;
  }, 0);

const constructDailyTotals = lineItems => ({
  mon: sumTotalsForWeekDay('mon', lineItems),
  tue: sumTotalsForWeekDay('tue', lineItems),
  wed: sumTotalsForWeekDay('wed', lineItems),
  thu: sumTotalsForWeekDay('thu', lineItems),
  fri: sumTotalsForWeekDay('fri', lineItems),
  sat: sumTotalsForWeekDay('sat', lineItems),
  sun: sumTotalsForWeekDay('sun', lineItems),
});

const TimesheetGrid = ({ isLocked, lineItems, timesheetId, endDate, user }) => {
  const projects = lineItems
    .reduce((acc, cur) => {
      if (acc.find(p => p._id === cur.project._id)) {
        return acc;
      }
      return [
        ...acc,
        {
          ...cur.project,
        },
      ];
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));

  const projectsWithTasks = projects.map(p => {
    return {
      ...p,
      lineItems: lineItems
        // lineItemIndex is used to determine which item in the timesheet lineItem array to remove.
        // since we are splitting lineItems into project groups, we have to carry over original index in the original lineItems array
        .map((i, index) => ({ ...i, lineItemIndex: index }))
        .filter(i => i.project._id === p._id),
    };
  });

  // if (lineItems.length === 0) {
  //   return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  // }

  const selectedDeliverableIds = lineItems.map(li => li.deliverable._id);

  return (
    <Table>
      <Header>
        <HeaderRow>
          <HeaderCol width="initial">Tasks</HeaderCol>
          <HeaderCol width="50px" />
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => {
            let observedHoliday = null;
            const currentDate = moment(endDate).subtract(6 - index, 'days');
            const displayedDay = `${day} ${currentDate.format('D')}`;
            const holiday = null;
            // const holiday = currentDate.isHoliday();
            // if (day === 'Fri') {
            //   observedHoliday = currentDate
            //     .clone()
            //     .add(1, 'days')
            //     .isHoliday();
            // }
            // if (day === 'Mon') {
            //   observedHoliday = currentDate
            //     .clone()
            //     .subtract(1, 'days')
            //     .isHoliday();
            // }

            // TODO handle bug with moment holdiday. Remove in future.
            if (currentDate.format('YYYYMMDD') === '20191122') {
              return (
                <HeaderCol key={day} width="80px" align="center">
                  <Day>{displayedDay}</Day>
                </HeaderCol>
              );
            }
            if (currentDate.format('YYYYMMDD') === '20191129') {
              return (
                <HeaderCol key={day} width="80px" align="center">
                  <Day>
                    <Tooltip title="Day after Thanksgiving">
                      <Tag color="blue">{displayedDay}</Tag>
                    </Tooltip>
                  </Day>
                </HeaderCol>
              );
            }

            return (
              <HeaderCol key={day} width="80px" align="center">
                <Day>
                  {observedHoliday || holiday ? (
                    <Tooltip title={observedHoliday ? `${observedHoliday} (observed)` : holiday}>
                      <Tag color={observedHoliday ? 'purple' : 'blue'}>{displayedDay}</Tag>
                    </Tooltip>
                  ) : (
                    displayedDay
                  )}
                </Day>
              </HeaderCol>
            );
          })}
          {!isLocked && <HeaderCol width="80px" align="center" />}
        </HeaderRow>
      </Header>
      <tbody>
        {lineItems.length > 0 ? (
          projectsWithTasks.map(p => {
            return (
              <React.Fragment key={p._id}>
                <ProjectName>
                  <td colSpan={9}>{p.name}</td>
                </ProjectName>
                {p.lineItems &&
                  p.lineItems.map((row, index) => (
                    <TimesheetGridRow
                      isLocked={isLocked || row.status === 'Approved'}
                      endDate={endDate}
                      key={`${p._id}${row.deliverable._id}`}
                      timesheetId={timesheetId}
                      lineItem={row}
                      lineItemIndex={row.lineItemIndex}
                      user={user}
                    />
                  ))}
              </React.Fragment>
            );
          })
        ) : (
          <tr>
            <td colSpan={9}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Your tasks for this timesheet will appear here."
              />
            </td>
          </tr>
        )}
        {!isLocked && (
          <Query query={GET_AVAILABLE_DELIVERABLES} variables={{ user: user }}>
            {({ loading, error, data }) => {
              if (error) throw new Error(error);
              return (
                <TimesheetGridRowAddNew
                  loading={loading}
                  isLocked={isLocked}
                  endDate={endDate}
                  timesheetId={timesheetId}
                  dailyTotals={constructDailyTotals(lineItems)}
                  user={user}
                  data={data}
                  lineItems={lineItems?.length || 0}
                  selectedDeliverableIds={selectedDeliverableIds}
                />
              );
            }}
          </Query>
        )}
      </tbody>
    </Table>
  );
};

export default TimesheetGrid;
