import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import SignInContainer from '../modules/auth/components/SignInContainer';
import SignOutContainer from '../modules/auth/components/SignOutContainer';
import { fadeIn, GlobalStyle } from '../globalStyles';
import Oauth from '../modules/auth/components/Oauth';
import ResetPassword from '../modules/auth/components/ResetPassword';

const Wrapper = styled.div`
  background-color: #e5e8ee;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  animation: ${fadeIn} 0.2s linear;
`;

const Content = styled.div`
  margin-top: 100px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-bottom: 2em;
`;

const Logo = styled.img`
  height: 100%;
`;

const AuthLayout = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <Switch>
        <Route
          path="/auth"
          render={() => (
            <Layout>
              <Content>
                <LogoWrapper>
                  <Logo src="/logo-wide.png" alt="Logo" />
                </LogoWrapper>
                <Switch>
                  <Route path="/auth/signin" component={SignInContainer} />
                  <Route path="/auth/signout" component={SignOutContainer} />
                  <Route path="/auth/reset-password" component={ResetPassword} />
                </Switch>
              </Content>
            </Layout>
          )}
        />
        <Route path="/_oauth" component={Oauth} />
      </Switch>
    </Wrapper>
  );
};

export default AuthLayout;
