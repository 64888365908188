import React, { useState } from 'react';
import { Modal, Button, Alert } from 'antd';
import CronScheduler from '../../common/components/CronScheduler';
import client from '../../../api/graphql/client';
import { EMAIL_REMINDER_JOB } from '../mutations';
import Card from '../../common/components/Card';
import cronstrue from 'cronstrue';

const TimesheetReminderCronConfig = ({ weeklyReminderEmailJob }) => {
  const { approvers, lastWeek, ongoingWeek } = weeklyReminderEmailJob || {};
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cronValue, setCronValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentScheduler, setCurrentScheduler] = useState('');
  const [currentongoingWeek, setOngoingWeek] = useState(ongoingWeek?.cronExpression || '');
  const [currentlastWeek, setLastWeek] = useState(lastWeek?.cronExpression || '');
  const [currentapprovers, setApprovers] = useState(approvers?.cronExpression || '');
  const [modalTitle,setModalTitle] = useState('');

  const showModal = value => {    
    setCurrentScheduler(value);
    switch (value) {
      case 'ongoingWeek':
        setCronValue(currentongoingWeek);
        setModalTitle("Set Reminder Email Schedule For Ongoing Week")
        break;
      case 'lastWeek':
        setCronValue(currentlastWeek);
        setModalTitle("Set Reminder Email Schedule For last Week")
        break;
      case 'approvers':
        setCronValue(currentapprovers);
        setModalTitle("Set Reminder Email Schedule For Approvers")
        break;
      // default:
      // code block
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsLoading(true);
    let variable = {};
    switch (currentScheduler) {
      case 'ongoingWeek':
        setOngoingWeek(cronValue);
        break;
      case 'lastWeek':
        setLastWeek(cronValue);
        break;
      case 'approvers':
        setApprovers(cronValue);
        break;
      // default:
      // code block
    }

    client
      .mutate({
        mutation: EMAIL_REMINDER_JOB,
        variables: {
          cronExpression: cronValue,
          cronDescription: '',
          currentScheduler,
        },
      })
      .then(() => {
        setIsLoading(false);
        setCurrentScheduler('');
      });
  };

  const handleCancel = () => {
    setCronValue('');
    setIsModalVisible(false);
  };
  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 p-2">
        <Card title="Set Reminder Email Schedule For Ongoing Week">
          <div style={{ padding: '10px', margin: '10px' }}>
            {currentongoingWeek && (
              <Alert
                message={cronstrue.toString(currentongoingWeek)}
                type="success"
                style={{marginBottom: '10px' }}
              />
            )}
            <Button type="primary" onClick={() => showModal('ongoingWeek')}>
              Click to configure
            </Button>
          </div>
        </Card>
      </div>
      <div className="w-full md:w-1/2 p-2">
        <Card title=" Set Reminder Email Schedule For last Week">
        <div style={{ padding: '10px', margin: '10px' }}>
          {currentlastWeek && (
            <Alert
              message={cronstrue.toString(currentlastWeek)}
              type="success"
              style={{marginBottom: '10px' }}
            />
          )}
          <Button type="primary" onClick={() => showModal('lastWeek')}>
            Click to configure
          </Button>
          </div>
        </Card>
      </div>
      <div className="w-full md:w-1/2 p-2">
        <Card title=" Set Reminder Email Schedule For Approvers">
        <div style={{ padding: '10px', margin: '10px' }}>
          {currentapprovers && (
            <Alert
              message={cronstrue.toString(currentapprovers)}
              type="success"
              style={{marginBottom: '10px' }}
            />
          )}
          <Button type="primary" onClick={() => showModal('approvers')}>
            Click to configure
          </Button>
          </div>
        </Card>
      </div>

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CronScheduler setter={setCronValue} getter={cronValue} />
      </Modal>
    </div>
  );
};

export default TimesheetReminderCronConfig;
