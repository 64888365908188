import React, { useState } from 'react';
import Card from '../../common/components/Card';
import { Tag } from 'antd';
import client from '../../../api/graphql/client';
import { REMOVE_FROM_GROUP, ADD_TO_GROUP } from '../mutations';

const { CheckableTag } = Tag;

const UserGroups = ({ title, groups, email, userId }) => {
  const [seniorManagement, setSeniorManagement] = useState(groups.includes('senior-management'));
  const [humanResource, setHumanResource] = useState(groups.includes('human-resources'));
  const [finance, setFinance] = useState(groups.includes('finance'));
  const [admin, setAdmin] = useState(groups.includes('admin'));
  const [vaccineReport, setVaccineReport] = useState(groups.includes('vaccine-report'));
  const tagStyle = {
    padding: '5px',
    border: '1px solid #ededed',
    cursor: 'pointer',
  };

  const handleChange = async (e, setter, value) => {
    setter(e);
    await client.mutate({
      mutation: e ? ADD_TO_GROUP : REMOVE_FROM_GROUP,
      variables: {
        userEmail: email,
        name: value,
      },
    });
  };

  return (
    <Card title={title} floating padded>
      <CheckableTag
        style={tagStyle}
        checked={seniorManagement}
        onChange={e => handleChange(e, setSeniorManagement, 'senior-management')}
      >
        Senior Management
      </CheckableTag>
      <CheckableTag
        style={tagStyle}
        checked={humanResource}
        onChange={e => handleChange(e, setHumanResource, 'human-resources')}
      >
        Human Resource
      </CheckableTag>
      <CheckableTag
        style={tagStyle}
        checked={finance}
        onChange={e => handleChange(e, setFinance, 'finance')}
      >
        Finance
      </CheckableTag>
      <CheckableTag
        style={tagStyle}
        checked={admin}
        onChange={e => handleChange(e, setAdmin, 'admin')}
      >
        Admin
      </CheckableTag>
      <CheckableTag
        style={tagStyle}
        checked={vaccineReport}
        onChange={e => handleChange(e, setVaccineReport, 'vaccine-report')}
      >
        Vaccine Report
      </CheckableTag>
    </Card>
  );
};

export default UserGroups;
