import React from 'react';
import { useGlobal } from 'reactn';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import TimesheetApprovalList from './TimesheetApprovalList';
import Card from '../../common/components/Card';
import { GET_TIMESHEETS_FOR_APPROVAL } from '../queries';

const TimesheetApprovalListContainer = () => {
  const [user] = useGlobal('user');

  return (
    <Query query={GET_TIMESHEETS_FOR_APPROVAL}>
      {({ loading, error, data }) => {
        if (error) throw new Error(error);
        return (
          <Card padded={false} withShadow floating title="Timesheet Approvals">
            <TimesheetApprovalList
              user={user}
              timesheets={get(data, 'projectManagement.timesheets')}
              loading={loading}
            />
          </Card>
        );
      }}
    </Query>
  );
};

export default TimesheetApprovalListContainer;
