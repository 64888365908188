import React from 'react';
import Card from '../Card';
import Table from '../Table';

const UserProjects = ({ title, data }) => {
  const projectData = data?.map(project => {
    const { name, projectManagers, _id } = project;
    let projectManager = '';
    projectManagers.map((item, i) => {
      projectManager = i === 0 ? `${item.name}` : `${projectManager} , ${item.name}`;
    });
    return {
      id: _id,
      projectName: name,
      projectManager,
      link: `/project-management/projects/${_id}/timesheets`,
    };
  });
  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: 'Project Manager',
      dataIndex: 'projectManager',
      key: 'projectManager',
    },
    {
      title: 'Project Link',
      width: 50,
      render: data => {
        const { link } = data;
        return <a href={link}>Link</a>;
      },
    },
  ];

  return (
    <Card title={title} floating>
      <div>
        <Table
          highlightOnHover
          columns={columns}
          pagination={false}
          dataSource={projectData.map((r, i) => ({ key: i, ...r }))}
          rowKey={r => r.id}
        />
      </div>
    </Card>
  );
};

export default UserProjects;
